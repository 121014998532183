import { OutlinedButton } from "../OutlinedButton";
import { SolidButton } from "../SolidButton";
import { Container, FixCoinValue, Footer, Header, Label, FixcoinBalance, InputSeparator } from "./styles";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { CopyPaste, QrCodeContainer, RadioContent, SentToMailTextContainer, WarningLabel } from "../BillPaymentDetails/styles";
import { useEffect, useState } from "react";
import { i18n } from "../../localization/i18n";
import { OutlinedSelect } from "../OutlinedSelect";
import { showToast } from "../CustomToast";
import { BiCopy } from "react-icons/bi";
import { CreatePaymentInfosModal } from "../../modules/Financial/components/PaymentInfos/components/CreatePaymentInfosModal";
import { typesErrors } from "../../utils/validateFormFields/yupErrors";
import { AddressModal } from "../AddressModal";
import { httpParams } from "../BillPaymentDetails/mocks";
import { IAddress, Licensee } from "../PersonModal/dtos";
import { Separator } from "../TipsModal/styles";
import * as Yup from "yup";
import api from "../../services/api";
import { ICurrencyInfos, useAuth } from "../../hooks/AuthContext";
import validateFormFields from "../../utils/validateFormFields";
import { BsCartPlusFill } from "react-icons/bs";
import { FixcoinItens } from "../FixcoinItens";

interface ICard {
  card_last_four_digits?: string;
  card_flag?: string;
  id?: string;
}
interface IOptions {
  optionText: string;
  value: string;
}

interface IPaymentDetails {
  onHandleCancel: () => void;
  isOpen: boolean;
  currency: ICurrencyInfos | undefined;
  licenseeFixcoins: number;
}

interface IFixcoinCards {
  price: string;
}

export function FixcoinPaymentDetails({
  onHandleCancel,
  isOpen,
  currency,
  licenseeFixcoins
}: IPaymentDetails) {
  const { user } = useAuth()

  const [depositValue, setDepositValue] = useState("");
  const [errors, setErrors] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [paymentInfos, setPaymentInfos] = useState<IOptions[]>([]);
  const [userAddresses, setUserAddresses] = useState<IOptions[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<IOptions>();
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [selectedPaymentCard, setSelectedPaymentCard] = useState<IOptions>();
  const [needCardConfirmation, setNeedCardConfirmation] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("pix");
  const [pixQrCodeUrl, setPixQrCodeUrl] = useState();
  const [pixQrCodeKey, setPixQrCodeKey] = useState();
  const [boletoUrl, setBoletoUrl] = useState();
  const [boletoKey, setBoletoKey] = useState();
  const [paidAmmount, setPaidAmmount] = useState("0");
  const [selectedValue, setSelectedValue] = useState<string>();
  const [selectedCard, setSelectedCard] = useState<number>(3);

  const fixcoinCards: IFixcoinCards[] = [
    {
      price: "35",
    },
    {
      price: "70",
    },
    {
      price: "105",
    },
    {
      price: "350",
    },
    {
      price: "700",
    },
    {
      price: "1000",
    },
  ]

  function clearData() {
    setDepositValue("");
    setPaymentMethod("pix");
    setPaidAmmount("0");
    setNeedCardConfirmation(false);
    setSelectedPaymentCard({} as IOptions);
    setAddNewAddress(false);
    setSelectedAddress({} as IOptions);
    setUserAddresses([] as IOptions[]);
    setPaymentInfos([] as IOptions[]);
    setLoading(false);
    setErrors({} as any);
    setSelectedValue("350");
    setSelectedCard(3);
  }

  useEffect(() => {
    clearData();
    handleGetUserPaymentInfos();
    getLicenseeAddresses(user.person.licensee.id);
  }, [isOpen]);

  async function payBill() {
    setLoading(true);
    let cipherInfo = {
      card_holder: "",
      encryptedNumber: "",
      encryptedValidationDate: "",
      encryptedSecurityCode: "",
    };

    if (
      ["card"].includes(paymentMethod) &&
      localStorage.getItem(
        `${selectedPaymentCard?.optionText.slice(
          selectedPaymentCard.optionText.length - 4
        )}`
      )
    ) {
      cipherInfo = JSON.parse(
        localStorage.getItem(
          `${selectedPaymentCard?.optionText.slice(
            selectedPaymentCard.optionText.length - 4
          )}`
        ) ?? ""
      );
    }

    try {
      const body = {
        payment_method: paymentMethod,
        quantity: selectedValue,
        price: (Number(selectedValue) * (currency?.fixcoin_exchange ?? 1)).toFixed(2),
        licensee_id: user.person.licensee.id,
        address_id: selectedAddress?.value,
      };

      const yup_validation = Yup.object().shape({
        payment_method: Yup.string(),
        address_id: Yup.string().required(typesErrors.required),
        card_id: Yup.string().when("payment_method", {
          is: "card",
          then: (field) => field.required(typesErrors.required),
          otherwise: (field) => field.nullable().notRequired(),
        }),
      });

      const validation = await validateFormFields(body, yup_validation, {
        payment_method: "",
        address_id: "",
        card_id: "",
      });

      if (validation.status === 400) {
        setErrors(validation.errors);
        showToast({
          type: "error",
          message: i18n.t("form_errors.alert"),
        });

        setLoading(false);
        return;
      }

      setErrors({});

      await api
        .post(`/transactions/buy`, body)
        .then((response) => {
          if (["card"].includes(paymentMethod) || response.data.paid) {
            showToast({
              type: "success",
              message: `${i18n.t("bills.success.payment")}`,
            });
          }
          if (body.payment_method === "pix") {
            setPixQrCodeUrl(response.data.charge.cobrancas[0].pix_qrcode);
            setPixQrCodeKey(response.data.charge.cobrancas[0].pix_chave);
          }
          if (body.payment_method === "boleto") {
            if (response.data.charge.cobrancas[0].linha_digitavel === null) {
              showToast({
                type: "success",
                message: `${i18n.t("bills.dataSentByMail")}`,
              });
            }
            setBoletoUrl(response.data.charge.cobrancas[0].url);
            setBoletoKey(response.data.charge.cobrancas[0].linha_digitavel);
          }
        });
    } catch (error: any) {
      if (error.response.data.message === "Card not valid") {
        showToast({
          type: "error",
          message: `${i18n.t("bills.error.transaction")}`,
        });
        return;
      } else if (error.response.data.message === "Insufficient funds") {
        showToast({
          type: "error",
          message: `${i18n.t("bills.error.insufficientFunds")}`,
        });
        return;
      }
      showToast({
        type: "error",
        message: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  }

  async function getLicenseeAddresses(id: string) {
    try {
      const response = await api.get(`/address/all/${id}`);

      setUserAddresses(
        response.data.map((address: IAddress) => {
          if (address.billing_address) {
            setSelectedAddress({
              optionText: `${address?.address}, ${address?.number}`,
              value: address.id,
            });
          }
          return {
            optionText: `${address?.address}, ${address?.number}`,
            value: address.id,
          };
        })
      );
    } catch (error) {
      showToast({
        type: "error",
        message: "Não foi possivel carregar seus endereços.",
      });
    }
  }

  async function handleGetUserPaymentInfos() {
    try {
      const response = await api.get(`/payment-infos`);

      if (response.data) {
        const cardsData = response.data.map((card: ICard) => ({
          optionText: `${localStorage.getItem(`${card.card_last_four_digits}`)
            ? JSON.parse(
              localStorage.getItem(`${card.card_last_four_digits}`) ?? ""
            ).card_holder
            : ""
            }  ****${card.card_last_four_digits}`,
          value: card.id,
        }));
        setPaymentInfos(cardsData);
      }
    } catch (error) {
      showToast({
        message: "Ocorreu um erro ao carregar as informações de pagamento",
        type: "error",
      });
    }
  }

  return (
    <Container>
      <Header>
        <BsCartPlusFill size={24} color="var(--fixit)" />
        {`${i18n.t(`topbar.coinFix.buy`)}`}
        <b>
          {`${i18n.t(`topbar.coinFix.end`)}`}
        </b>
      </Header>

      {!pixQrCodeUrl && !pixQrCodeKey && !boletoKey && !boletoUrl &&
        <>
          <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <FixcoinItens
              fixcoinPrices={fixcoinCards}
              setSelectedValue={setSelectedValue}
              setSelectedCard={setSelectedCard}
              selectedCard={selectedCard}
            />
            {/*<div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>

        <OutlinedInput
          label="Quantidade de Fixcoins"
          inputName={"Depósito FixCoin"}
          type="text"
          mask="999999"
          value={depositValue}
          handleChange={(data) => {
            if (!isNaN(data)) {
              setDepositValue(data)
            }
          }}
        />
      </div> */}

            <div style={{ display: "flex", flexDirection: "row", gap: "1rem", justifyContent: "center" }}>
              <FixcoinBalance>
                <Label>
                  <>
                    Valor da compra:
                  </>
                </Label>
                <FixCoinValue>
                  <>
                    {(Number(selectedValue) * (currency?.fixcoin_exchange ?? 1)).toFixed(2)} {currency?.code}
                  </>
                </FixCoinValue>
              </FixcoinBalance>

              <FixcoinBalance>
                <Label>
                  <>
                    Saldo pós-compra:
                  </>
                </Label>
                <FixCoinValue>
                  <>
                    {Number(licenseeFixcoins) + Number(selectedValue)}{" "}
                    {i18n.t("topbar.coinFix.plural")}
                  </>
                </FixCoinValue>
              </FixcoinBalance>
            </div>
          </div>
          <InputSeparator />
        </>
      }

      <>
        {!pixQrCodeUrl && !pixQrCodeKey && !boletoKey && !boletoUrl ? (
          <>
            <RadioGroup
              row
              onChange={(event) =>
                setPaymentMethod(event.target.value as any)
              }
              value={paymentMethod}
            >
              <RadioContent>
                <FormControlLabel
                  value="pix"
                  control={<Radio />}
                  label={`${i18n.t("bills.pix")}`}
                />
                <FormControlLabel
                  value="boleto"
                  control={<Radio />}
                  label={`${i18n.t("bills.ticket")}`}
                />
                {/*<FormControlLabel
                  value="card"
                  control={<Radio />}
                  label={`${i18n.t("bills.card")}`}
                />
                */}
              </RadioContent>
            </RadioGroup>
            {paymentMethod === "boleto" &&
              <WarningLabel>AVISO: Pagamentos via boleto podem demorar até 48hrs para serem compensados. Só após esse período os FixCoins serão creditados</WarningLabel>
            }
            {!needCardConfirmation && ["card"].includes(paymentMethod) ? (
              <div className="address">
                <OutlinedSelect
                  error={errors?.card_id}
                  options={paymentInfos}
                  label={`${i18n.t("bills.paymentCard")}`}
                  handleSelect={(event) => {
                    if (
                      !localStorage.getItem(
                        `${event?.optionText.slice(
                          event.optionText.length - 4
                        )}`
                      )
                    ) {
                      setNeedCardConfirmation(true);
                    }
                    setSelectedPaymentCard({
                      optionText: event.optionText,
                      value: String(event.value),
                    });
                  }}
                  selectedOption={selectedPaymentCard?.optionText ?? ""}
                />
                <div className="newAddress">
                  <span>{`${i18n.t("bills.didntFindYourCard")}`}</span>
                  <strong onClick={() => setNeedCardConfirmation(true)}>
                    {`${i18n.t("bills.register")}`}
                  </strong>
                </div>
              </div>
            ) : ["card"].includes(paymentMethod) ? (
              <div className="addAddress">
                <Separator />
                <CreatePaymentInfosModal
                  isCardConfirmation={needCardConfirmation}
                  methodId={null}
                  handleCloseModal={() => {
                    setSelectedPaymentCard({
                      optionText: "",
                      value: "",
                    });
                    setNeedCardConfirmation(false);
                  }}
                  onMethodUpsert={handleGetUserPaymentInfos}
                />
                <Separator />
              </div>
            ) : (
              <></>
            )}
            {!addNewAddress ? (
              <div className="address">
                <OutlinedSelect
                  error={errors?.address_id}
                  label={`${i18n.t("bills.billing_address")}`}
                  options={userAddresses}
                  handleSelect={(selected) => {
                    setSelectedAddress({
                      optionText: selected.optionText,
                      value: String(selected.value),
                    });
                  }}
                  selectedOption={selectedAddress?.optionText ?? ""}
                />
                <div className="newAddress">
                  <span>{`${i18n.t("bills.didntFindYourAddress")}`}</span>
                  <strong onClick={() => setAddNewAddress(!addNewAddress)}>
                    {`${i18n.t("bills.register")}`}
                  </strong>
                </div>
              </div>
            ) : (
              <div className="addAddress">
                <Separator />
                <AddressModal
                  closeModal={() => setAddNewAddress(!addNewAddress)}
                  licensee={user.person.licensee as unknown as Licensee}
                  getLicenseeAddresses={getLicenseeAddresses}
                />
              </div>
            )}
            {!addNewAddress && !needCardConfirmation && (
              <Footer>
                <OutlinedButton text="Cancelar" onClick={onHandleCancel} />
                <SolidButton
                  text={`${i18n.t("bills.pay")}`}
                  onClick={payBill}
                  loading={loading}
                  disabled={loading}
                  fullWidth
                />
              </Footer>
            )}
          </>
        ) : boletoKey && boletoUrl ? (
          <QrCodeContainer>
            <a href={`${boletoUrl}`} target="_blank" rel="noreferrer">
              {`${i18n.t("bills.linkToAccessTicket")}`}
            </a>
            <CopyPaste>
              <div className="Header">
                <span>{`${i18n.t("bills.barCode")}`}</span>
                <BiCopy
                  size={20}
                  onClick={() =>
                    navigator.clipboard.writeText(boletoKey ?? "")
                  }
                />
              </div>
              {boletoKey}
            </CopyPaste>
            <SentToMailTextContainer>
              <span>{`*${i18n.t("bills.dataSentByMail")}*`}</span>
            </SentToMailTextContainer>
          </QrCodeContainer>
        ) : (
          <QrCodeContainer>
            <img src={`${pixQrCodeUrl}`} alt="PIX QRCODE" />
            <CopyPaste>
              <div className="Header">
                <span>{`${i18n.t("bills.pixCopyAndPaste")}`}</span>
                <BiCopy
                  size={20}
                  onClick={() =>
                    navigator.clipboard.writeText(pixQrCodeKey ?? "")
                  }
                />
              </div>
              {pixQrCodeKey}
            </CopyPaste>
            <SentToMailTextContainer>
              <span>{`*${i18n.t("bills.dataSentByMail")}*`}</span>
            </SentToMailTextContainer>
          </QrCodeContainer>
        )}
      </>
    </Container>
  )
}
