import styled, { css } from "styled-components";
import global from "../../global";
import dropdownArrowDown from "../../assets/dropdown-arrow-down.svg";

interface InputProps {
  readonly inputWidth: string;
}

interface ButtonProps {
  disabled?: boolean;
}

export const Container = styled.div``;

export const SelectPrinterContainer = styled.div``;

export const PreHeatingHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const BorderButton = styled.button`
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 10px;
  line-height: 0;
  border: 2px solid var(--printed);
  color: var(--printed);
  border-radius: 25px;
  padding: 20px 15px;
  background-color: var(--white);
`;

export const PrintButton = styled.button<ButtonProps>`
  padding: 12px 15px;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: 10px;
  color: var(--white);

  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  transition: all 0.2s;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        background-color: #a558d1;
      }
    `}

  background-color: ${({ disabled }) => (disabled ? "#ccc" : "var(--fixit)")};
`;

export const CloseButton = styled.button<ButtonProps>`
  padding: 12px 15px;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: 10px;
  color: var(--white);

  cursor: pointer;
  transition: all 0.2s;

  background-color: var(--fixit);
`;

export const Select = styled.select<InputProps>`
  border: 1px solid var(--light-gray);
  border-radius: 6px;

  font-size: 18px;
  color: var(--dark-gray);
  text-align: left;
  padding-left: 10px;

  height: 40px;
  width: ${(props) => props.inputWidth};
  max-width: 300px;

  padding: 0 8px;
  appearance: none;
  background-image: url(${dropdownArrowDown});
  background-color: var(--white);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
    .small.width.unit}) {
    width: 100%;
    max-width: unset;
  }
`;

export const SelectOption = styled.option`
  font-size: 16px;
`;

export const DropdownContainer = styled.div`
  position: relative;
  width: 300px;
`;

export const FindPrinterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FindPrinter = styled.p`
  font-size: 14px;
  color: var(--fixit);
  margin-top: 6px;
  cursor: pointer;
  margin-bottom: 20px;
`;

export const PrinterDisplay = styled.div`
  border: 1px solid #d8d8d8;
  padding: 5px 5px;
  border-radius: 10px;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  column-gap: 10px;
`;
export const TemperatureDisplay = styled.div`
  display: flex;
  column-gap: 5px;
  justify-content: center;
  padding-bottom: 5px;
`;

export const Field = styled.div`
  color: var(--dark-gray);
  padding-top: 6px;
  font-size: 15px;
`;

export const CrtButtons = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 5px;
`;

export const PrintingActionButton = styled.button<ButtonProps>`
  display: flex;
  padding: 5px 5px;
  border-radius: 10px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  border: 1px solid ${({ disabled }) => (disabled ? "#ccc" : "var(--fixit)")};
  background-color: var(--white);
  svg {
    color: ${({ disabled }) => (disabled ? "#ccc" : "var(--fixit)")} !important;
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        background-color: var(--fixit);
        svg {
          color: var(--white) !important;
        }
      }
    `};
`;

export const PrintingProblemButton = styled.button<ButtonProps>`
  display: flex;
  padding: 5px 5px;
  border-radius: 10px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  border: 1px solid ${({ disabled }) => (disabled ? "#ccc" : "var(--warning)")};
  background-color: var(--white);
  svg {
    color: ${({ disabled }) =>
    disabled ? "#ccc" : "var(--warning)"} !important;
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        background-color: var(--warning);
        svg {
          color: var(--white) !important;
        }
      }
    `}
`;

export const PrintingCancelButton = styled.button<ButtonProps>`
  display: flex;
  padding: 5px 5px;
  border-radius: 10px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  border: 1px solid
    ${({ disabled }) => (disabled ? "#ccc" : "var(--formError)")};
  background-color: var(--white);
  svg {
    color: ${({ disabled }) =>
    disabled ? "#ccc" : "var(--formError)"} !important;
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        background-color: var(--warning);
        svg {
          color: var(--white) !important;
        }
      }
    `}
`;

export const ExtruderCommandsButton = styled.button<ButtonProps>`
  display: flex;
  padding: 5px 5px;
  border-radius: 10px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  border: 1px solid ${({ disabled }) => (disabled ? "#ccc" : "var(--fixit)")};
  background-color: var(--white);
  svg {
    color: ${({ disabled }) =>
    disabled ? "#ccc" : "var(--fixit)"} !important;
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        background-color: var(--warning);
        svg {
          color: var(--white) !important;
        }
      }
    `}
`;
