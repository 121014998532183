import { useEffect, useState } from "react";
import { Bill, useAuth } from "../../../../hooks/AuthContext";
import { i18n } from "../../../../localization/i18n";
import api from "../../../../services/api";
import { MiniSolutionCard } from "./components/MiniSolutionCard";
import {
  SolutionsContainer,
  Header,
  HeaderContainer,
  SolutionsCarrousel,
  Container,
  InfoGraphsContainer,
  NumbersContainer,
  FilterContainer,
  Form,
  CarrouselContainer,
} from "./styles";
import { GiWireCoil } from "react-icons/gi";
import { NumberCardBox } from "./components/NumberCardBox";
import { BsBasket } from "react-icons/bs";
import { AiOutlineCalendar, AiOutlineStar } from "react-icons/ai";
import { RiBillLine, RiRestartLine, RiShoppingCart2Line } from "react-icons/ri";
import { MdOutlineAttachMoney, MdOutlineMedicalServices } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { BarChartCard } from "./components/BarChartCard";
import { BarVerticalChartCard } from "./components/BarVerticalChartCard";
import { Tag, TagsContainer } from "../../../Orders/styles";
import { IFilter, IPrice } from "../../../Orders/dtos";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { Filter } from "./components/Filter";
import { BillsCard } from "./components/BillsCard";
import { TrophyWithNumber } from "./components/TrophyWithNumber";
import { showToast } from "../../../../components/CustomToast";
import { useNavigate } from "react-router-dom";
import { usePrinters } from "../../../../hooks/PrintersContext";
import { orderTypes, roles } from "../../../../utils/constants";
import { orderTypeOptions, solutionTypeOptions } from "../../../Orders/mocks";
import { useOnboard } from "../../../../hooks/OnboardContext";

export interface IReport {
  day: number;
  month: number;
  yearByMonth: number[];
  lastSevenDays: number[];
}

interface IMostOrderedReturn {
  name_key: string;
  link_img: string;
  orderscount: number;
}

interface IDiagnosisIncidenceResponse {
  names: string[];
  count: number[];
}
interface IDashboardData {
  patientCount: number;
  actualBillValue: number;
  bills: Bill[];
  mostOrderedSolutions: IMostOrderedReturn[];
  diagnosisIncidenceChartObject: IDiagnosisIncidenceResponse | null;
  filamentMaterialCost: number | null;
}

export interface ISolution {
  id: string;
  name: string;
  name_key: string;
  category_key: string;
  field_key: string;
  form_type?: string;
  link_img: string;
  download_link: string;
  category: string;
  link_guide: string;
  number_measures: number;
  sample: ISample;
  solution_category: ICategory;
  is_custom: boolean;
  is_standard: boolean;
  is_download: boolean;
  standards: Array<any>;
}

export interface ISample {
  id?: string;
  solution_id?: string;
  printer_id?: string;
  link?: string;
}

export interface ICategory {
  id: string;
  name: string;
  category_key: string;
  field_key: string;
}

export function NewDashboard() {
  const navigate = useNavigate();
  const { setNavigationAction, currency, user } = useAuth();
  const { userHasPrinter, defaultPrinter } = usePrinters()
  const { licensee } = useOnboard();
  const [filePrice, setFilePrice] = useState<IPrice>();

  const [language, setLanguage] = useState("pt-BR");
  const [report, setReport] = useState<IReport>({} as IReport);
  const [filter, setFilter] = useState<IFilter>({
    month: startOfMonth(new Date()),
    period: undefined,
  });
  const [dashboardData, setDashboardData] = useState<IDashboardData>();

  const DashboardCards = [
    {
      icon: <BsBasket />,
      title: `${i18n.t("dashboard.requestsDay")}`,
      value: report?.day ?? 0,
    },
    {
      icon: <RiShoppingCart2Line />,
      title: `${i18n.t("dashboard.requestsMonth")}`,
      value: report?.month ?? 0,
    },
    {
      icon: <FiUsers />,
      title: `${i18n.t("dashboard.newPatients")}`,
      value: dashboardData?.patientCount ?? 0,
      hasAditionalInfo: true,
      aditionalInfo: `${i18n.t("dashboard.patientsInfo")}`,
    },
    {
      icon: <MdOutlineAttachMoney />,
      title: `${i18n.t("dashboard.bill")}`,
      value: `${currency?.symbol ?? "$"} ${(userHasPrinter && dashboardData?.actualBillValue) ? dashboardData.actualBillValue : 0
        }`,
      hide: !userHasPrinter
    },
    {
      icon: <GiWireCoil />,
      title: `${i18n.t("dashboard.filamentUse")}`,
      value: `${dashboardData?.filamentMaterialCost ?? 0}g`,
      hasAditionalInfo: true,
      aditionalInfo: `${i18n.t("dashboard.filamentInfo")}`,
    },
  ];

  async function handleSubmit(solution: ISolution) {
    if (window.confirm(
      "Você deseja confirmar a compra?"
    )
    ) {
      await api
        .get(`/prices/calc/${solution.id}`)
        .then(async ({ data }) => {
          if (!licensee) {
            return;
          }

          const body = {
            provider: !userHasPrinter ? licensee.provider_id : null,
            solution_id: solution.id,
            category:
              !userHasPrinter
                ? orderTypes.fixiter
                : orderTypeOptions.find((option) => option.value === 'is_patient')
                  ?.value || orderTypes.patient,
            printer_id:
              user.role === roles.licensee || user.role === roles.employee
                ? defaultPrinter?.value
                : undefined,
            total: data.file_price,
            solution_type: "standard",
            licensee_id: licensee.id,
            quantity: 1,
          };

          try {
            /*
              Faltando Color, solution id, printer_id,
            */
            await api.post("/orders", body);

            showToast({
              type: "success",
              message: i18n.t("customSolution.successToast"),
            });

            user.role === roles.fixiter || user.role === roles.employee
              ? navigate(`/${user.role.toLowerCase()}/orders`)
              : navigate("/orders");
          } catch (error) {
            showToast({
              type: "error",
              message: "Não foi possível salvar pedido",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setFilePrice(undefined);
        });
    }

  }

  async function getDashboardData() {
    const queryList = [];

    queryList.push(`language=${language}`);

    if (filter?.month !== undefined) {
      const [month, year] = format(filter?.month, "MM/yyyy").split("/");
      queryList.push(`month=${month}`);
      queryList.push(`year=${year}`);
    }

    filter?.period?.from !== undefined &&
      queryList.push(`from=${filter?.period?.from.toJSON()}`);
    filter?.period?.to !== undefined &&
      queryList.push(`to=${filter?.period?.to?.toJSON()}`);

    try {
      await api
        .get(`/reports/dashboard?${queryList.join("&")}`)
        .then((response) => {
          setDashboardData(response.data);
        });
    } catch (error) {
      showToast({
        type: "error",
        message: `${i18n.t("dashboard.error")}`,
      });
    }
  }

  useEffect(() => {
    if (filter.month !== undefined) {
      getDashboardData();
    }
  }, [filter]);

  useEffect(() => {
    api.get(`/reports`).then((response) => {
      setReport(response.data);
    });

    const currentLanguage = localStorage.getItem("i18nextLng");
    if (currentLanguage === null) {
      return;
    } else if (currentLanguage.startsWith("pt")) {
      setLanguage("pt-BR");
    } else if (currentLanguage.startsWith("es")) {
      setLanguage("es");
    } else if (currentLanguage.startsWith("en")) {
      setLanguage("en");
    }
  }, []);

  useEffect(() => {
    setNavigationAction({
      hasButton: false,
      description: i18n.t("dashboard.header"),
    });
  }, [setNavigationAction]);

  function formatDate(date: Date) {
    return format(date, "dd/MM/yyyy");
  }

  const currentYear = new Date().getFullYear();

  return (
    <Container>
      <Form>
        <TagsContainer>
          {filter?.month && (
            <Tag>
              <span>{format(new Date(filter?.month), "MM/yyyy")}</span>
              <RiRestartLine
                size={14}
                onClick={() => {
                  setFilter((prevState) => ({
                    ...prevState,
                    month: startOfMonth(new Date()),
                  }));
                }}
              />
            </Tag>
          )}
          {filter?.period && (
            <Tag>
              <span>
                {formatDate(filter.period.from)} -{" "}
                {formatDate(filter.period.to)}
              </span>
              <RiRestartLine
                size={14}
                onClick={() => {
                  setFilter((prevState) => ({
                    ...prevState,
                    period: {
                      from: startOfMonth(new Date()),
                      to: endOfMonth(new Date()),
                    },
                  }));
                }}
              />
            </Tag>
          )}
        </TagsContainer>
        <FilterContainer>
          <Filter
            onChange={(filterData) => {
              setFilter(filterData);
            }}
            filter={filter}
          />
        </FilterContainer>
      </Form>
      <NumbersContainer>
        {DashboardCards.map((card) => (
          <>
            {!card.hide &&
              <NumberCardBox
                icon={card.icon}
                title={card.title}
                value={card.value}
                hasAditionalInfo={card.hasAditionalInfo}
                aditionalInfo={card.aditionalInfo}
              />
            }
          </>
        )
        )}
      </NumbersContainer>

      <InfoGraphsContainer>
        <BarChartCard
          icon={<MdOutlineMedicalServices />}
          header={`${i18n.t("dashboard.caseIncidence")}`}
          list={{
            data: dashboardData?.diagnosisIncidenceChartObject?.count ?? [],
            label: dashboardData?.diagnosisIncidenceChartObject?.names ?? [],
          }}
        />

        <BarVerticalChartCard
          header={`${i18n.t("dashboard.requestsYear")} ${currentYear}`}
          icon={<AiOutlineCalendar />}
          data={report.yearByMonth}
        />

        <BillsCard
          header={`${i18n.t("bills.bills")}`}
          icon={<RiBillLine />}
          billsArray={dashboardData?.bills ?? []}
        />
      </InfoGraphsContainer>

      <SolutionsContainer>
        <HeaderContainer>
          <AiOutlineStar size={25} color="var(--fixit)" />
          <Header>{`${i18n.t("dashboard.mostAskedSolutions")}`}</Header>
        </HeaderContainer>
        <SolutionsCarrousel>
          {dashboardData?.mostOrderedSolutions.map((data, index) => {
            return (
              <CarrouselContainer
                onClick={() => handleSubmit(data)}
              >
                <TrophyWithNumber number={index + 1} />
                <MiniSolutionCard link_img={data.link_img} />
              </CarrouselContainer>
            );
          })}
        </SolutionsCarrousel>
      </SolutionsContainer>
    </Container>
  );
}
