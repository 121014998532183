import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
`;

export const Separator = styled.div`
background: var(--light-gray);
height: 1px;
width: 100%;
margin: 0 0 1rem;
`
export const AssociateContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
margin: 0 0 1rem;
`

export const FixitLogo = styled.img`
width: 100px;
height: 100px;
border-radius: 50%;
margin-right: 20px;
background-color: var(--dark-gray);
object-fit: cover;
`

export const DataContainer = styled.div`
h3 {
    display: inline;
    color: var(--dark-gray);
    margin-right: 3px;
}

p {
    color: var(--gray);
}

a {
    text-decoration-line: none;
}

.whatsapp {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 182px;
    gap: 0.2rem;
    padding: 2px 0 2px 0;
    margin: 3px 0 3px 0;
    color: var(--active-green);
    background-color: white;
    border: 1px solid var(--active-green);
    border-radius: 5px;

        :hover {
            color: var(--active-green-hover);
            border-color: var(--active-green-hover);
        }
`