import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0 0.5rem;
    gap: 1rem;

    .address {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 12px 0 0;
    color: var(--dark-gray);

    .newAddress {
      color: var(--fixit);
      display: flex;
      justify-content: flex-end;
      gap: 2.5px;
      strong {
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
      }
      span {
        font-size: 14px;
        color: var(--gray);
      }
    }
  }
`

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    color: var(--fixit);
    font-size: 24px;
    gap: 0.3rem;
`

export const DepositLabel = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: var(--gray)
`

export const FixcoinBalance = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 5px;
`

export const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--gray);
`;

export const FixCoinValue = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: var(--active-green);
`

export const InputSeparator = styled.div`
    width: 100%;
    border-bottom: 1px solid var(--light-gray);
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 1rem 0 0;
`
