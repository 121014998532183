import { useNavigate } from "react-router-dom";
import { RiInformationLine, RiDownload2Line } from "react-icons/ri";
import ImagemCoringa from "../../assets/imagem_coringa.png";

import {
  Container,
  GifContainer,
  Header,
  Footer,
  DownloadButton,
  CustomButton,
  VerticalContainer,
  PriceInfo,
  StandardButton,
  NotAvailableOverlay,
} from "./styles";
import api from "../../services/api";
import { useEffect, useState } from "react";
import { showToast } from "../../components/CustomToast";
import { i18n } from "../../localization/i18n";
import { useAuth } from "../../hooks/AuthContext";
import AwardImg from "../../assets/icons/award_standards_plano.svg";
import PenImg from "../../assets/icons/pen_custom_plano.svg";
import { roles, solutionTypes } from "../../utils/constants";
import { ISignature } from "../../hooks/OnboardContext";
import { usePrinters } from "../../hooks/PrintersContext";

export interface IPrice {
  id: string;
  order_type: string;
  currency: string;
  file_price: number;
}
interface NewProductBoxProps {
  solutionId: string;
  licenseeCountry: string;
  solutionPrice?: number;
  solutionName: string;
  solutionCategory: string;
  solutionDescription?: string;
  is_custom: boolean;
  is_standard: boolean;
  is_download: boolean;
  standards: Array<any>;
  link_img: string;
  download_link: string;
  solutionKey: string;
  solutionForm?: string;
  isAvailable?: boolean;
  onInfoClick?: () => any;
  ordersCount: boolean;
  handleCreateStandardOrder: () => void;
}

export function NewProductBox({
  solutionId,
  licenseeCountry,
  solutionName,
  solutionCategory,
  solutionDescription = "",
  solutionKey,
  solutionForm,
  solutionPrice,
  is_custom,
  is_standard,
  is_download,
  standards,
  link_img,
  download_link,
  isAvailable = true,
  ordersCount,
  onInfoClick = () => { },
  handleCreateStandardOrder
}: NewProductBoxProps) {
  const navigate = useNavigate();
  const [price, setPrice] = useState<IPrice>();
  const [signature, setSignature] = useState<ISignature>();

  const { userHasPrinter } = usePrinters()

  const { user } = useAuth();
  const [role, setRole] = useState("");

  useEffect(() => {
    setUserRole();
  }, [user]);

  const setUserRole = () => {
    if (user.role === roles.fixiter) {
      setRole("/" + roles.fixiter.toLowerCase());
    } else if (user.role === roles.employee) {
      setRole("/" + roles.employee.toLowerCase());
    } else {
      setRole("");
    }
  };

  async function getUserSignature() {
    try {
      await api.get(`/signatures/licensee`).then((response) => {
        setSignature(response.data);
      });
    } catch (error) {
      return;
    }
  }

  async function getPrice() {
    const queryList = [];
    queryList.push(`is_active=${true}`);
    queryList.push(`country=${licenseeCountry}`);
    queryList.push(`solution_id=${solutionId}`);

    try {
      const response = await api.get(`/prices/solution?${queryList.join("&")}`);
      setPrice(response.data);
    } catch (error) {
      showToast({
        type: "error",
        message: i18n.t(`financial.error.get_prices`),
      });
    }
  }

  useEffect(() => {
    getPrice();
    getUserSignature();
  }, []);

  return (
    <Container>
      {!isAvailable && <NotAvailableOverlay />}
      <Header>
        <RiInformationLine
          onClick={onInfoClick}
          size={24}
          color="var(--fixit)"
        />
      </Header>

      <GifContainer>
        <img
          src={
            link_img
              ? `${process.env.REACT_APP_STORAGE_BASE_URL}${link_img}`
              : ImagemCoringa
          }
          alt="gif"
        />
      </GifContainer>

      <Footer>
        <div className="solution_infos">
          {!link_img && <div className="solution_name">{solutionName}</div>}
          {!userHasPrinter || user.role === roles.employee ? null : (
            <div className="solution_types">
              {price && (
                <PriceInfo>
                  <b>{`${i18n.t(`financial.prices.field.file_price`)}`} </b>
                  {parseFloat(price.file_price.toString()).toLocaleString(
                    "pt-br",
                    {
                      style: "currency",
                      currency: price.currency,
                    }
                  )}
                </PriceInfo>
              )}
            </div>
          )}
        </div>
        {is_download ? (
          <DownloadButton
            href={`${process.env.REACT_APP_STORAGE_BASE_URL}${download_link}`}
          >
            <span>{`${i18n.t("solutions.download")}`}</span>
            <RiDownload2Line color="white" className="download_icon" />
          </DownloadButton>
        ) : (
          <VerticalContainer>
            {is_standard && (
              <StandardButton
                disabled={
                  !price ||
                  !isAvailable ||
                  !signature?.plan.standard_orders ||
                  !ordersCount
                }
                type="button"
                onClick={handleCreateStandardOrder}
              >
                <span>{`${i18n.t("standardSolution.buy")}`}</span>
                <img src={AwardImg} alt="" />
              </StandardButton>
            )}
            {is_custom && (
              <CustomButton
                disabled={
                  !price ||
                  !isAvailable ||
                  !signature?.plan.custom_orders ||
                  !ordersCount
                }
                type="button"
                onClick={() => {
                  if (isAvailable) {
                    navigate({
                      pathname: `${role}/order/${solutionKey}/${solutionForm || solutionTypes.custom
                        }`,
                    });
                  }
                }}
              >
                <span>{`${i18n.t("customSolution.buy")}`}</span>
                <img src={PenImg} alt="" />
              </CustomButton>
            )}
          </VerticalContainer>
        )}
      </Footer>
    </Container>
  );
}
