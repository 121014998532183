import styled from "styled-components";
import global from "../../global";

type FixcoinItem = {
    isSelected: boolean;
}

export const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    gap: 1rem;
`

export const FixcoinItem = styled.div<FixcoinItem>`
    display: flex;
    align-items: center;

    width: 30%;
    height: 65px;
    border: 1px solid var(--fixit);
    border-radius: 20px;
    
    background-color: ${({ isSelected }) => isSelected ? "var(--fixit)" : "var(--white)"};
    color: ${({ isSelected }) => isSelected ? "var(--white)" : "var(--fixit)"};

    cursor: pointer;
    transition: all 0.5s;


    &:hover {
        background: var(--fixit);
        color: var(--white);
    }

    @media (max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
        width: 29%;
        text-align: center;
    }

`

export const FixcoinValue = styled.div`
    display: flex;
    justify-content: center;

    width: 100%;
    font-size: 16px;
    font-weight: 500;

    @media (max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
        font-size: 18px;
    }
`