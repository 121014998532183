import React, { useEffect, useState } from "react";
import { i18n } from "../../localization/i18n";
import { showToast } from "../../components/CustomToast";
import { ProgressBar } from "../../components/ProgressBar";
import {
  Container,
  PrintButton,
  CloseButton,
  FindPrinterContainer,
  FindPrinter,
  CrtButtons,
  PrintingActionButton,
  PrinterDisplay,
  TemperatureDisplay,
  Field,
  PrintingCancelButton,
  PreHeatingHeader,
  ExtruderCommandsButton,
} from "./styles";
import api from "../../services/api";
import {
  RiPauseCircleLine,
  RiPlayCircleLine,
  RiCloseCircleLine,
} from "react-icons/ri";
import { MdOutlineKeyboardDoubleArrowUp, MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";
import { IFileStream, IPrinter } from "../../modules/Orders/dtos";
import LineBreakTransformerForStream from "../../utils/stream/LineBreakTransformerForStream";
import { usePrinters } from "../../hooks/PrintersContext";
import ByteSliceTransformer from "../../utils/stream/ByteSliceTransformer";
import DecoderTransformer from "../../utils/stream/DecoderTransformer";
import { ALGO } from "../../utils/constants";
import { EncryptionFactory } from "../../utils/crypto/GenerateEncryptionKey";
import { Loader } from "../Loader";
import axios from "axios";
import JsonRpcReaderTransformer from "../../utils/stream/JsonRpcReaderTransformer";
import { SolidButton } from "../SolidButton";
import Popover from "../Popover";
interface ISendToPrinterProps {
  fileStream: IFileStream;
  printingId: string;
  progress: number;
  printer: IPrinter;
  setContentLength: (value: React.SetStateAction<number>) => void;
  setReceived: (value: React.SetStateAction<number>) => void;
  resumePrinting: () => void;
  printStart: () => void;
  printEnd: () => void;
  setPrintHasStarted: (value: boolean) => void;
}

/* Pegar o máximo X da impressora */
const commands = {
  getEndstopStates: "M119",
  changeFilament: "M600",
  pause: "M0", // testando .... o pause é o M25, e o pause M226 é safety, executa o que estiver na fila.
  resume: "M108",
  autoReportTemperature: "M155 S2",
  bedTemperature: "M190",
  extruderTemperature: "M109",
  temperatureStatus: "M105 X0",
  relativePositioning: "G91",
  absolutePositioning: "G90",
  extruderCleanMove: "G0 X150.0", // Movimento com a mesma velocidade
  retractExtruder: "G1 F2400 E-5", // Retract filament 5mm at 40mm/s to prevent stringing
  moveZAxisUp: "G0 F5000 Z20", // Move Z Axis up 20mm to allow filament ooze freely
  swtichOffFan: "M106 S0",
  switchOffExtruder: "M104 S0",
  switchOffBed: "M140 S0",
  stopInconditional: "M2",
  sendExtruderToHomePositioning: "G0 X0 Y400 F5000",
  sendMessage: (message: string) => `M117 ${message}`,
  restoreBedTemperature: (bedPrintingTemperature: number) =>
    `M190 S${bedPrintingTemperature} T0`,
  restoreExtruderTemperature: (extruderPrintingTemperature: number) =>
    `M109 S${extruderPrintingTemperature} T0`,
};
interface Blob {
  readonly size: number;
  readonly type: string;
  arrayBuffer(): Promise<ArrayBuffer>;
  slice(start?: number, end?: number, contentType?: string): Blob;
  stream(): ReadableStream<Uint8Array>;
  text(): Promise<string>;
}

const SendToPrinterStream = ({
  progress,
  printingId,
  printer,
  setContentLength,
  setReceived,
  resumePrinting,
  printStart,
  printEnd,
  fileStream,
  setPrintHasStarted,
}: ISendToPrinterProps) => {
  const [gCodeControlCommand, setGCodeControlCommand] =
    useState<string>("ready");
  const [control, setControl] = useState<string>("human");
  const [printerMessage, setPrinterMessage] = useState<string>();
  const [gcodeStreamReader, setGcodeStreamReader] =
    useState<ReadableStreamDefaultReader<string>>();
  const [printerReader, setPrinterReader] =
    useState<ReadableStreamDefaultReader<string>>();
  const [printerWriter, setPrinterWriter] =
    useState<WritableStreamDefaultWriter<string>>();
  const [extruderTemperature, setExtruderTemperature] = useState<number>(0);
  const [extruderTemperatureGoal, setExtruderTemperatureGoal] =
    useState<number>(0);
  const [bedTemperature, setBedTemperature] = useState<number>(0);
  const [bedTemperatureGoal, setBedTemperatureGoal] = useState<number>(0);
  const [zOffSet, setZOffSet] = useState<string>("0")
  const [displayMessage, setDisplayMessage] = useState<string>();
  const [wakeLock, setWakeLock] = useState<WakeLockSentinel>();
  const { defaultPrinter, setPrinting } = usePrinters();
  const [printingFase, setPrintingFase] = useState<string>();
  const [cryptoFileContentLength, setCryptoFileContentLength] = useState(0);
  const [cryptoFileReadableStream, setCryptoFileReadableStream] =
    useState<ReadableStream<Uint8Array>>();
  const [downloadedData, setDownloadedData] = useState(0);
  const [printCancelled, setPrintCancelled] = useState(false);
  const [preHeatingBedAndExtruder, setPreHeatingBedAndExtruder] =
    useState(false);
  const [printCanBeConcluded, setPrintCanBeConcluded] = useState(false);
  const [connectedPrinter, setConnectedPrinter] = useState(false);
  const [webSocketPrinter, setWebSocketPrinter] = useState<any>()
  const [loadingConnection, setLoadingConnection] = useState(false)
  const [blockControllers, setBlockControllers] = useState(false)

  /*
  Apresentar que está aquecendo a mesa.
  "TargetBed:65"
  "T:26.93 /0 B:27.33 /65"
  */
  /*
  Apresentar que está aquecendo o Extrusor
  TargetExtr0:210
  T:131.57 /210 B:65.72
  */
  // cryptos/9db19c7ab8c3_MioFix_MIOFIX_KIT.fixit
  // 3319273

  async function getDeviceData() {
    try {
      const response = await axios.get("https://geolocation-db.com/json/");
      return response.data;
    } catch (error) {
      console.error("Falha em obter endereço IP", error)
    }
  }

  async function verifyIfUserDeviceIsAuthorizedToPrint() {
    try {

      await getDeviceData().then(async (device) => {

        const queryList = []

        queryList.push(`id=${localStorage.getItem("@FixitApp:Device")}`)
        device?.IPv4 && queryList.push(`ip=${device.IPv4}`)
        device?.city && queryList.push(`city=${device.city}`)
        device?.state && queryList.push(`state=${device.state}`)
        device?.country_code && queryList.push(`country=${device.country_code}`)
        device?.latitude && queryList.push(`latitude=${device.latitude}`)
        device?.longitude && queryList.push(`longitude=${device.longitude}`)
        queryList.push(`timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`)
        queryList.push(`user_agent=${navigator.userAgent}`)
        queryList.push(`screen_resolution=${window.screen.width}x${window.screen.height}`)

        await api.get(`/user-devices/auth?${queryList.join("&")}`).then(response => {
          if (response.data) {
            setPrintingFase("prepare")
          } else {
            showToast({
              message: 'Device não autorizado à imprimir',
              type: 'error'
            })
          }
        })
      })

    } catch (error) {
      console.log(error)
      showToast({
        message: 'Device não autorizado à imprimir',
        type: 'error'
      })
    }
  }

  function obtainExtruderAndBedTemperature(input: string): {
    extruderTemperature: number | null;
    bedTemperature: number | null;
  } {
    const regex =
      /T:(\d+\.\d+|\d+)\s*\/\s*(\d+\.\d+|\d+).*?B:(\d+\.\d+|\d+)\s*\/\s*(\d+\.\d+|\d+)/;
    const match = input.match(regex);
    if (match) {
      const extruderTemperature = parseFloat(match[1]);
      const bedTemperature = parseFloat(match[3]);
      return { extruderTemperature, bedTemperature };
    } else {
      return { extruderTemperature: null, bedTemperature: null };
    }
  }
  async function interrumptPrinting() {
    setDisplayMessage(commands["sendMessage"]("Stream ERROR"));
    try {
      await api.delete(`/printings/${printingId}`);
    } catch (err) {
      console.log(err);
    }
  }
  async function sendProgressToServerAndBlockPrinter() {
    setPrinting({
      id: printingId,
      progress: progress,
      sync: false,
    });
  }
  const printStep = (step: string) => {
    setPrintingFase(step);
  };
  useEffect(() => {
    if (cryptoFileContentLength && cryptoFileReadableStream) {
      (async () => {
        const root = await navigator.storage.getDirectory();
        const dirHandle = await root.getDirectoryHandle("tmpFixitFolder", {
          create: true,
        });
        const fileHandle = await dirHandle.getFileHandle("gcode.fixit", {
          create: true,
        });
        const writable = await fileHandle.createWritable({
          keepExistingData: false,
        });
        const writer = writable.getWriter();
        await cryptoFileReadableStream.pipeTo(
          new WritableStream({
            write(chunk) {
              setDownloadedData((prevState) => prevState + chunk.length);
              writer.write(chunk);
            },
          })
        );
        await writer.close();
        printStep("print");
      })();
    }
  }, [cryptoFileContentLength, cryptoFileReadableStream]);
  useEffect(() => {
    if (
      cryptoFileContentLength &&
      printingFase === "print" &&
      downloadedData > 0 &&
      downloadedData === cryptoFileContentLength
    ) {
      (async () => {
        const root = await navigator.storage.getDirectory();
        const dirHandle = await root.getDirectoryHandle("tmpFixitFolder", {
          create: false,
        });
        const fileHandle = await dirHandle.getFileHandle("gcode.fixit", {
          create: false,
        });
        const file: Blob = await fileHandle.getFile();
        const stream = file.stream();
        if (!process.env.REACT_APP_FIXIT_FILE_KEY) {
          throw new Error("Cannot find ");
        }
        const chunk_size = +(process.env.REACT_APP_CHUNK_SIZE || "1024");
        const counter_size = +(process.env.REACT_APP_COUNTER_SIZE || "16");
        const textStream = stream
          ?.pipeThrough(
            new TransformStream(
              new ByteSliceTransformer(chunk_size + counter_size)
            )
          )
          ?.pipeThrough(
            new TransformStream(
              new DecoderTransformer(
                ALGO,
                counter_size,
                await new EncryptionFactory().getEncryptionKey(
                  ALGO,
                  process.env.REACT_APP_FIXIT_FILE_KEY
                ),
                (received: number) => {
                  setReceived((prevState) => prevState + received);
                }
              )
            )
          )
          .pipeThrough(new TextDecoderStream());
        const lineBreakStream = textStream.pipeThrough(
          new TransformStream(new LineBreakTransformerForStream())
        );
        // await sendToPrinterTest(lineBreakStream.getReader());
        await sendToPrinter(lineBreakStream.getReader());
      })();
    }
  }, [downloadedData, cryptoFileContentLength, printingFase]);
  const startPrinting = async (response: Response) => {
    if (!response.ok || !response.body) {
      throw response.statusText;
    }
    /* const length = response.headers.get("Content-Length");
    if (!length) {
      throw new Error("Missing Content-Length data");
    }
    const numberLength : number = +length
    setCryptoFileContentLength(numberLength);*/
    setCryptoFileReadableStream(response.body);
  };
  const preparePrint = async (link: string) => {
    try {
      // printStart()
      const token = localStorage.getItem("@FixitApp:token");
      const response = await fetch(link, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok || !response.body) {
        throw response.statusText;
      }
      if (response.status === 200) {
        await startPrinting(response);
      } else {
        printStart();
        printStep("print");
        const length = response.headers.get("Content-Length");
        if (!length) {
          throw new Error("Wrong response data");
        }
        // const numberLength : number = +length
        // setContentLength(numberLength);
        const stream = response.body;
        const slicedStream = stream?.pipeThrough(
          new TransformStream(new ByteSliceTransformer(1024))
        );
        // const [streamPrinter, streamLogger] = slicedStream.tee()
        const logStream = slicedStream.pipeThrough(
          new TransformStream({
            transform(chunk, controller) {
              setReceived((prevState) => prevState + chunk.length);
              controller.enqueue(chunk);
            },
          })
        );
        const textStream = logStream.pipeThrough(new TextDecoderStream());
        const lineBreakStream = textStream.pipeThrough(
          new TransformStream(new LineBreakTransformerForStream())
        );
        // await sendToPrinterTest(lineBreakStream.getReader());
        await sendToPrinter(lineBreakStream.getReader());
      }
    } catch (err) {
      console.log(err);
      if (printingId) {
        interrumptPrinting();
      }
      showToast({
        type: "error",
        message: "Impressão indisponível",
      });
      return;
    }
  };
  function cancelPrinting() {
    setControl("stop");
    setPrintCancelled(true);
  }
  function tooglePauseStopPrinting() {
    if (control === "paused") {
      printerWriting(
        commands["restoreBedTemperature"](bedTemperatureGoal)
      ).then(() => {
        setControl("restoreExtruderTemperature");
      });
    } else {
      setControl("pause");
    }
  }

  async function printerReading() {
    if (!printerReader) {
      return;
    }
    try {
      const { value, done } = await printerReader.read();

      if (done) {
        setPrinterReader(undefined);
        setPrinterWriter(undefined);
        return;
      }
      if (!value) {
        printerReading();
        return;
      }
      if (value[0]?.heater_bed) {
        setBedTemperature(value[0].heater_bed.temperature)
      }
      if (value[0]?.extruder) {
        setExtruderTemperature(value[0].extruder.temperature)
      }
      if (value?.gcode_move) {
        setZOffSet(value.gcode_move.homing_origin?.[2])
      }

      setPrinterMessage((prevState) =>
        prevState !== value ? value : `${value} odd`
      );
    } catch (e) {
      console.log(e);
    }
  }
  async function printerWriting(printerInstruction: string): Promise<void> {
    if (!printerWriter) {
      return;
    }
    return printerWriter.write(
      JSON.stringify({
        jsonrpc: "2.0",
        method: "printer.gcode.script",
        params: {
          script: printerInstruction,
        },
        id: 1,
      }))
  }

  async function sendMessageToDisplay(): Promise<void> {
    if (displayMessage) {
      // await printerWriting(displayMessage)
    }
  }

  function getCommands(arrayOfCommands: string[], command: string): string | undefined {
    return arrayOfCommands.find((c) => c.includes(command))
  }

  async function readGCodeFromServer(): Promise<string | undefined> {
    const { value }: ReadableStreamReadResult<string> =
      await gcodeStreamReader!.read();

    if (value) {
      /* if (value.charAt(0) === ";" || value.charAt(0) === "(") {
         return await readGCodeFromServer();
       }
       */
      const commandArray = value.split("\n");

      const bedTemperatureGoal = getCommands(commandArray, commands["bedTemperature"]);
      const extruderTemperatureGoal = getCommands(commandArray, commands["extruderTemperature"]);
      const preHeatingConcluded = getCommands(commandArray, "G1 F3600");
      const printConcluded = getCommands(commandArray, "M84");

      if (bedTemperatureGoal && !preHeatingBedAndExtruder && printingFase === "print" && progress <= 2) {
        setPreHeatingBedAndExtruder(true);
        setBedTemperatureGoal(+bedTemperatureGoal.split(' ')[1].slice(1));
      }
      if (extruderTemperatureGoal && !preHeatingBedAndExtruder && printingFase === "print" && progress <= 2) {
        setPreHeatingBedAndExtruder(true);
        setExtruderTemperatureGoal(+extruderTemperatureGoal.split(' ')[1].slice(1));
      }
      if (preHeatingConcluded) {
        setPreHeatingBedAndExtruder(false);
      }
      if (printConcluded) {
        setPrintCanBeConcluded(true);
      }

      return value;
    }
  }

  useEffect(() => {
    if (printerMessage && (typeof printerMessage === "string" || Array.isArray(printerMessage)) && printerMessage.indexOf("ok") !== -1) {
      if (control === "human" && gCodeControlCommand !== "ready") {
        if (gCodeControlCommand === "ready") {
          setControl("printer");
        } else {
          try {
            const value = gCodeControlCommand;
            setGCodeControlCommand("ready");
            printerWriting(value)
              .finally(() => {
                setControl("printer");
              })
              .catch((err) => console.log(err));
          } catch (err) {
            console.log(err);
          }
        }
      } else if (control === "printer") {
        readGCodeFromServer()
          .then((value) => {
            if (value) {
              printerWriting(`${value}`).finally(() => printerReading());
            }
          })
          .catch((err) => console.log(err));
      } else if (control === "pause") {
        printerWriting(commands["pause"]).then(() => {
          setControl("extruderOff");
        });
      } else if (control === "extruderOff") {
        printerWriting(commands["sendExtruderToHomePositioning"]).finally(
          () => {
            setControl("paused");
          }
        );
      } else if (control === "restoreExtruderTemperature") {
        printerWriting(
          commands["restoreExtruderTemperature"](extruderTemperatureGoal)
        ).then(() => {
          setControl("extruderCleanMove");
        });
      } else if (control === "extruderCleanMove") {
        printerWriting(commands["extruderCleanMove"]).finally(() => {
          setGCodeControlCommand("ready");
          setControl("printer")
        });
      } else if (control === "stop") {
        printerWriting(commands["moveZAxisUp"]).finally(() => {
          setControl("switchOffBed");
        });
      } else if (control === "switchOffBed") {
        printerWriting(commands["switchOffBed"]).finally(() => {
          setControl("switchOffExtruder");
        });
      } else if (control === "switchOffExtruder") {
        printerWriting(commands["switchOffExtruder"]).finally(() => {
          setControl("swtichOffFan");
        });
      } else if (control === "swtichOffFan") {
        printerWriting(commands["swtichOffFan"]).finally(() => {
          setControl("stopExtruder");
        });
      } else if (control === "stopExtruder") {
        printerWriting(commands["relativePositioning"]).finally(() => {
          setControl("stoppingRetractExtruder");
        });
      } else if (control === "stoppingRetractExtruder") {
        printerWriting(commands["retractExtruder"]).finally(() => {
          setControl("stopAbsolutePositioning");
        });
      } else if (control === "stopAbsolutePositioning") {
        printerWriting(commands["absolutePositioning"]).finally(() => {
          setControl("stoppingExtruderOff");
        });
      } else if (control === "stoppingExtruderOff") {
        printerWriting(commands["sendExtruderToHomePositioning"]).finally(
          () => {
            setControl("stopInconditional");
          }
        );
      } else if (control === "stopInconditional") {
        printerWriting(commands["stopInconditional"]).finally(() => {
          setControl("stopped");
          printerReader?.releaseLock();
          // interrumptPrinting();
        });
      }
    } else {
      printerReading();
    }
  }, [printerMessage]);
  useEffect(() => {
    if (
      control === "printer" ||
      control === "pause" ||
      control === "paused" ||
      control == "extruderCleanMove" ||
      control == "relativePositioning" ||
      control == "absolutePositioning" ||
      control == "retractExtruder" ||
      control == "extruderOff" ||
      control == "stopAbsolutePositioning" ||
      control === "restoreExtruderTemperature" ||
      control === "restoreBedTemperature" ||
      control === "stop" ||
      control === "switchOffExtruder" ||
      control === "swtichOffFan" ||
      control == "stopExtruder" ||
      control == "stoppingRetractExtruder" ||
      control == "stoppingExtruderOff" ||
      control === "stopInconditional"
    ) {
      printerReading();
    }
  }, [control]);
  useEffect(() => {
    const isPaused = control === "restoreBedTemperature";
    if (gCodeControlCommand !== "ready") {
      setControl("human");
      if (isPaused) {
        setTimeout(printerReading, 1000);
      }
    }
  }, [gCodeControlCommand]);
  useEffect(() => {
    if (gcodeStreamReader && printerReader && printerWriter) {
      printerWriting(commands["sendMessage"]("FIX IT STREAM"))
        .then(() => {
          printerWriter.write(JSON.stringify({
            jsonrpc: "2.0",
            method: "printer.objects.subscribe",
            params: {
              objects: {
                extruder: ["temperature"],
                heater_bed: ["temperature"],
                gcode_move: ["homing_origin"]
              }
            },
            id: 2100,
          }));
        })
        .finally(() => {
          setControl("printer");
        });
    }
  }, [gcodeStreamReader, printerReader, printerWriter]);

  function getZOffsetPosition() {
    printerWriter &&
      printerWriter.write(JSON.stringify({
        jsonrpc: "2.0",
        method: "printer.objects.subscribe",
        params: {
          objects: {
            gcode_move: null
          }
        },
        id: 2101,
      }));

    setBlockControllers(false)
  }

  async function ConnectPrinterWebSocket() {
    try {
      setLoadingConnection(true)

      const wss = await new WebSocketStream(`wss://${localStorage.getItem("@FixitApp:printerIP")}/websocket`)

      await wss.opened.catch(() => {
        setLoadingConnection(false)
        throw new Error("Connection failed")
      })

      setWebSocketPrinter(wss)
      setConnectedPrinter(true)
      setLoadingConnection(false)
    } catch {
      showToast({
        type: "error",
        message: "Não foi possivel conectar com a impressora",
      });
      setConnectedPrinter(false)
    }
  }

  async function sendToPrinter(
    chunksReader: ReadableStreamDefaultReader<string>
  ) {

    if (!webSocketPrinter) {
      showToast({
        type: "error",
        message: "Ops...Você não possui dispositivos conectados",
      });
      return;
    }

    const { readable, writable } = await webSocketPrinter.opened;

    setGcodeStreamReader(chunksReader);
    if (webSocketPrinter && writable && readable) {
      const lineReader = readable
        .pipeThrough(new TransformStream(new JsonRpcReaderTransformer()))
        .getReader();
      setPrinterReader(lineReader);
      const textEncoder = new TextEncoderStream();
      const writableStreamClosed = textEncoder.readable.pipeTo(
        writable
      );
      setPrinterWriter(textEncoder.writable.getWriter());

    } else {
      showToast({
        type: "error",
        message: "Ops...Impossível se comunicar com a impressora",
      });
      return;
    }
  }
  function verifyProgress() {
    !!printingId && sendProgressToServerAndBlockPrinter();
  }
  useEffect(() => {
    if ("wakeLock" in navigator) {
      navigator.wakeLock
        .request("screen")
        .then((wl: WakeLockSentinel) => setWakeLock(wl));
    }
    if (fileStream.standard_file) {
      setCryptoFileContentLength(fileStream.standard_file.crypto_length);
      setContentLength(fileStream.standard_file.content_length);
    } else {
      setCryptoFileContentLength(fileStream.custom_file.crypto_length);
      setContentLength(fileStream.custom_file.content_length);
    }
  }, []);
  useEffect(() => {
    verifyProgress();
  }, [progress]);
  useEffect(() => {
    if (
      process.env.REACT_APP_BASE_URL &&
      fileStream?.id &&
      printingFase === "prepare"
    ) {
      setPrintHasStarted(true);
      preparePrint(
        `${process.env.REACT_APP_BASE_URL}/printings/${fileStream.id}/print`
      );
    }
  }, [printingFase]);

  const controlBlock: Boolean =
    control === "pause" ||
    control === "extruderCleanMove" ||
    control === "relativePositioning" ||
    control === "absolutePositioning" ||
    control === "retractExtruder" ||
    control === "extruderOff" ||
    control === "stopAbsolutePositioning" ||
    control === "restoreExtruderTemperature" ||
    control === "restoreBedTemperature" ||
    control === "stop" ||
    control === "switchOffExtruder" ||
    control === "swtichOffFan" ||
    control === "stopExtruder" ||
    control === "stoppingRetractExtruder" ||
    control === "stoppingExtruderOff" ||
    control === "stopInconditional";

  return (
    <Container>
      <PrinterDisplay>
        <TemperatureDisplay>
          <Field>
            <strong>Z-Offset: {Number(zOffSet).toFixed(2)}</strong>
          </Field>
          <Field>
            <strong>Ext: {extruderTemperature.toFixed(0)}°/</strong>
            {extruderTemperatureGoal}°
          </Field>
          <Field>
            <strong>Bed: {bedTemperature.toFixed(0)}°/</strong>
            {bedTemperatureGoal}°
          </Field>
        </TemperatureDisplay>
        <CrtButtons>
          <Popover label="Subir Z-Offset" position="left">
            <ExtruderCommandsButton
              disabled={
                progress >= 100 ||
                progress <= 0 ||
                preHeatingBedAndExtruder ||
                printingFase !== 'print' ||
                blockControllers
              }
              onClick={() => {
                setBlockControllers(true)
                printerWriting(`SET_GCODE_OFFSET Z_ADJUST=+0.01 MOVE=1`)
                setInterval(getZOffsetPosition, 2000)
              }}
            >
              <MdOutlineKeyboardDoubleArrowUp size={28} />
            </ExtruderCommandsButton>
          </Popover>
          <Popover label="Descer Z-Offset" position="left">
            <ExtruderCommandsButton
              disabled={
                progress >= 100 ||
                progress <= 0 ||
                preHeatingBedAndExtruder ||
                printingFase !== 'print' ||
                blockControllers
              }
              onClick={() => {
                setBlockControllers(true)
                printerWriting(`SET_GCODE_OFFSET Z_ADJUST=-0.01 MOVE=1`)
                setInterval(getZOffsetPosition, 2000)
              }}
            >
              <MdOutlineKeyboardDoubleArrowDown size={28} />
            </ExtruderCommandsButton >
          </Popover>

          <Popover label="Pausar impressão" position="left">
            <PrintingActionButton
              disabled={
                gCodeControlCommand !== "ready" ||
                !!controlBlock ||
                progress >= 100 ||
                printCancelled ||
                progress <= 0 ||
                preHeatingBedAndExtruder
              }
              onClick={tooglePauseStopPrinting}
            >
              {control === "paused" ? (
                <RiPlayCircleLine size={28} />
              ) : (
                <RiPauseCircleLine size={28} />
              )}
            </PrintingActionButton>
          </Popover>
          <Popover label="Cancelar impressão" position="left">
            <PrintingCancelButton
              disabled={
                gCodeControlCommand !== "ready" ||
                !!controlBlock ||
                progress >= 90 ||
                printCancelled ||
                progress <= 0 ||
                preHeatingBedAndExtruder
              }
              onClick={cancelPrinting}
            >
              {<RiCloseCircleLine size={28} />}
            </PrintingCancelButton>
          </Popover>
        </CrtButtons>
      </PrinterDisplay>
      {!connectedPrinter ? (
        <SolidButton
          fullWidth
          fontSize={18}
          disabled={
            /* !defaultPrinter?.port?.readable &&
            process.env.NODE_ENV !== "development"
          */
            !localStorage.getItem("@FixitApp:printerIP")
          }
          type="button"
          onClick={() =>
            ConnectPrinterWebSocket()
          }
          text={`${i18n.t("Conectar impressora")}`}
          loading={loadingConnection}
        />
      ) :
        !printingFase ? (
          <PrintButton
            disabled={
              /* !defaultPrinter?.port?.readable &&
              process.env.NODE_ENV !== "development"
            */
              false
            }
            type="button"
            onClick={() =>
              setPrintingFase("prepare")
            }
          >
            {`${i18n.t("orders.sendToPrinter")}`}
          </PrintButton>
        ) : printCancelled ? (
          <CloseButton type="button" onClick={() => window.location.reload()}>
            {`${i18n.t("topbar.signout")}`}
          </CloseButton>
        ) : preHeatingBedAndExtruder ? (
          <CloseButton type="button" disabled={true}>
            <PreHeatingHeader>
              {`${i18n.t("orders.printChoose.printControl.preHeatingBedAndExtruder")}`}
              <Loader loaderSize={20} />
            </PreHeatingHeader>
          </CloseButton>
        ) : (progress >= 100 || control === "stopped") && printCanBeConcluded ? (
          <CloseButton type="button" onClick={() => printEnd()}>
            {`${i18n.t("orders.conclude")}`}
          </CloseButton>
        ) : (
          <>
            {printingFase === "test" && <></>}
            {printingFase === "prepare" && (
              <>
                <ProgressBar
                  text={`Preparando ${(
                    (downloadedData / cryptoFileContentLength) *
                    100
                  ).toFixed(0)}%`}
                  percentage={(downloadedData / cryptoFileContentLength) * 100}
                />
              </>
            )}
            {printingFase === "print" && (
              <>
                <ProgressBar
                  text={`Imprimindo ${progress.toFixed(0)}%`}
                  percentage={progress}
                />
                {progress >= 95 && (
                  <FindPrinterContainer onClick={resumePrinting}>
                    <FindPrinter>{`${i18n.t(
                      "orders.printComplete"
                    )}`}</FindPrinter>
                  </FindPrinterContainer>
                )}
              </>
            )}
          </>
        )
      }
    </Container >
  );
};
export default SendToPrinterStream;
