import {
  ILanguageMessages,
  ITranslateCreators,
  ITranslateCustomSolution,
  ITranslateDashboard,
  ITranslateDisabled_user,
  ITranslateDropZone,
  ITranslateErrorMessages,
  ITranslateEvaluation,
  ITranslateFileList,
  ITranslateFinancial,
  ITranslateForm_Errors,
  ITranslateGlobal,
  ITranslateGoBackBtn,
  ITranslateNavMenu,
  ITranslateOptionsBtn,
  ITranslateOrderBox,
  ITranslateOrders,
  ITranslateProfile,
  ITranslateSearchBar,
  ITranslateSidebar,
  ITranslateSolutions,
  ITranslateStandardSolution,
  ITranslateSuccess,
  ITranslateSurgicalPlanning,
  ITranslateTopBar,
  ITranslateUserRegistration,
  ITranslateFixiter,
  ITranslatePrinter,
  ITranslateCourses,
  ITranslateLogin,
  ITranslateTips,
  ITranslateAddress,
  ITranslatePerson,
  ITranslateCoins,
  ITranslateUserProfile,
  ITranslateNoInfo,
  ITranslateLanguage,
  ITranslateBills,
  ITranslateTransactions,
} from "./dtos";

const language: ITranslateLanguage = {
  userLanguage: "es",
};

const transactions: ITranslateTransactions = {
  header: "Actas",
};

const noInfo: ITranslateNoInfo = {
  partner: "Aún no tienes socios registrados.",
  patient: "Aún no tienes paciente registrados.",
  address: "Aún no tienes dirección registrados.",
  card: "Aún no tienes tarjeta registrados.",
  transaction: "Aún no tienes transacción registrados."
};

const bills: ITranslateBills = {
  valueToBePaid: "El monto a pagar:",
  completePayment: "Pagar factura restante",
  value: "Valor",
  card: "Tarjeta",
  ticket: "Boleto",
  pix: "PIX",
  paymentCard: "Tarjeta para pago",
  didntFindYourCard: "¿No encontró su tarjeta?",
  didntFindYourAddress: "¿No encontró su dirección?",
  billing_address: "Dirección de envio",
  pay: "Realizar pago",
  changePaymentMethod: "Cambiar método de pago",
  register: "Registrar un",
  linkToAccessTicket: "Enlace para acceder a la entrada en PDF",
  barCode: "Código de barras",
  pixCopyAndPaste: "PIX copiar y pegar",
  bills: "Facturado",
  requestBill: "Solicitar facturación",
  latePaymentInfo:
    "*Pagos realizados después del vencimiento, 10% de multa y 2% de interés",
  dataSentByMail: "Dados enviados vía email",
  error: {
    payment: "No se puede hacer el pago",
    transaction: "Ocurrió un error en la transacción.",
    mailBill: "No se puede hacer el pago",
    insufficientFunds: "Fondos insuficientes",
  },
  success: {
    payment: "Pago realizado con éxito",
    mailBill: "Cargo solicitado con éxito",
  },
};

const userProfile: ITranslateUserProfile = {
  addresses: "Mis direcciones",
  patients: "Mis pacientes",
  partners: "Mis compañeros",
  paymentInfos: "Tarjetas",
  users: "Mis usuarios",
  closure: "Financiero",
  myProfile: "Mi perfil",
  addressLabel: "Direcciones",
  patientLabel: "Pacientes",
  userLabel: "Compañeros",
  partnerLabel: "Usuarios",
  examsLabel: "Examenes",
  closureResume: "Resumen de facturación",
  ordersMade: "Pedidos realizados",
  ordersPaid: "Pedidos pagados",
  descriptions: {
    fixPoint: "Número de pedidos que eres proveedor.",
    closure: "Pedidos realizados durante:",
    ordersPaid: "Total de pedidos que usted pagó.",
    ordersMade: "Total de pedidos realizados durante el período de cierre.",
    ordersCourtesy: "Total de pedidos que se han marcado como gratuitos.",
    fixPointPopover: "Comprobar qué pedidos",
    closurePopover: "Consultar pedidos realizados en el periodo actual",
  },
  my_profile: "Mi Perfil",
  profile_picture: {
    register: "Registrar Foto de Perfil",
    adjust: "Haz clic para registrar tu foto de perfil y ajustarla.",
    success: "¡Imagen de perfil registrada con éxito!",
    error:
      "Error al registrar tu foto de perfil. Por favor, inténtelo de nuevo más tarde.",
    register_profile_picture: "Registrar",
  },
  password: {
    success: "Password changed successfully!",
    error: 'Wasn"t possible to change your password.',
  },
  edit_profile_data: "Editar Foto de Perfíl",
  edit_user_data: "Editar Datos de Usuario",
  edit_licensee_data: "Editar Datos Del Licenciatario",
  no_permission: "No tienes permiso para editar los datos del licenciatario.",
  yes: "Sí",
  no: "No",
  profile_data: "Datos Personales",
  user_data: "Datos de Usuario",
  licensee_data: "Datos del Licenciatario",
  personal_data: {
    document: "Número del Documento",
    phone: "Teléfono",
    country: "País",
    state: "Estado",
    city: "Ciudad",
    success: "¡Datos personales actualizados con éxito!",
    naturalPerson: "Persona Física",
  },
  data_user: {
    email: "E-mail",
    name: "Nombre",
    update: "Actualizar Datos de Usuario",
  },
  data_licensee: {
    fantasy_name: "Nombre Fantasía",
    enterprise_name: "Nombre de la Empresa",
    document: "Documento",
    situation: "Situacíon",
    phone: "Teléfono de Negocios",
    company_phone: "Teléfono de la Empresa",
    contract: "Contrato",
    printer: "Impresora",
    fixpoint: "FixPoint",
    update: "Actualizar Datos Del Licenciatario",
    manager_name: "Nombre Del Gerente",
    instagram: "Instagram",
    region: "Región",
    printers: "Impresoras",
  },
  selectedPrinters: "Impresoras Seleccionadas",
  noPrinterSelected: "Ninguna Impresora Seleccionada",
  actualClosure: "Facturación abierta",
  previousClosure: "Facturación cerrada",
};

const fixiterOnboarding: ITranslateFixiter = {
  introduction:
    "¡Hola Fixiter! ¿Listo para comenzar a ofrecer lo mejor a sus pacientes?",
  proceed: "Continuar",
  initialKit: "Solicita tu kit de inicio",
  firstStepHeader:
    "Conoce un poco más sobre la tecnología Fix it en este video:",
  secondStepHeader: "Todo sobre nuestra tecnología de impresión 3D",
  thirdStepHeader: "Ahora vamos a aprender cómo aplicar PunhoFix",
  firstDescription:
    "Pero antes de comenzar, expliquemos cómo va a ser, ¿de acuerdo?",
  description: "Nuestro proceso de puesta en marcha consta de dos pasos:",
  stepOne: "Tecnología Fix it",
  stepTwo: "Aplicación de la ortesis principal (PuñoFix)",
  stepThree: "Cómo usar la Plataforma",
  startTheOnboard: "INICIAR",
  startToUseTheApp: "EMPIEZA A USAR LA APLICACIÓN",
  sendCertificate: "Enviar foto de mi diploma",
  send: "Enviar",
  cancel: "Cancelar",
  sendDiploma: "Enviar diploma",
  chooseFile: "Seleccione un archivo para enviar (.png, .jpeg, .jpg)",
  selectSolutions: "SELECCIONE SOLUCIONES",
  document_success: "¡Documento enviado con éxito!",
  document_failed:
    "¡No se pudo enviar el documento! Póngase en contacto con SAF.",
  conclusionHeader:
    "¡No pasa mucho tiempo antes de que te conviertas en un Fixiter!",
  firstConclusionDescription:
    "Pero enhorabuena por terminar la formación de la plataforma, ya podemos decir que sois parte de Fix it y de la revolución en salud, nuestra bienvenida.",
  conclusionDescription:
    "Ahora vamos a validar tu información para liberar tu acceso como Fixiter, necesito que envíes una foto de tu diploma a nuestro WhatsApp, te responderemos en 24 horas. Así que no pierda el tiempo, haga clic en el botón a continuación para ser redirigido y validar su nombre de usuario, una vez aprobado, ¡podrá realizar su primer pedido y brindar la mejor experiencia a su paciente!",
  attachments: {
    header: "Archivos",
    portfolio: "Descarga el portafolio",
    tools: "Enlace para comprar herramientas y suministros.",
    startKit: "Solicite el kit de inicio",
    goodPractices:
      "Guía de buenas prácticas con la férula Fix it, para que el profesional la entregue al paciente",
    heatBlower: "Soplador de calor",
    scissors: "Tijeras",
    measuringTape: "Cinta métrica",
    velcro: "Velcro",
    tubularMesh: "Malla tubular (10cm de diámetro)",
  },
  stepTwoDescription: `• En este próximo paso, deberá seguir algunas pautas:
  La aplicación es muy sencilla, pero debes tener a mano las herramientas necesarias,
  Si no es así, acceda al siguiente enlace:`,
  firstConclusionWelcome: "¡Nuestra bienvenida al futuro de la salud!",
  initialKitDescription: `• Ahora con todas las pautas seguidas, ¿continuamos con el video de la aplicación de nuestra órtesis PunhoFix?`,
  attachmentsToolDescription: `• Los enlaces a continuación son referencias para la compra, la mayoría de
  suministros y herramientas que puede encontrar en las tiendas cercanas a
  usted, reduciendo el tiempo y el costo de envío.`,
  attachmentsStartKitDescription:
    "• Para practicar, solo solicita tu Starter Kit a continuación, recordando que es completamente gratis.",
};

const userRegistration: ITranslateUserRegistration = {
  connector: "y",
  stepOne: "Crear una cuenta",
  stepTwo: "Datos de registro",
  stepThree: "Datos comerciales",
  stepFour: "Planes",
  nextStep: "Ventaja",
  prevStep: "Devolver",
  name: "Nombre",
  email: "E-mail",
  password: "Contraseña",
  password_confirmation: "Confirmación de contraseña",
  fillCodes: "Introduce el código que te enviamos a tu email",
  fillNameAndEmail: "Introduce tu email y contraseña",
  fillData: "Completa tus datos",
  country: "País",
  cpf: "Registro de persona fisica",
  cellphone: "Celúla",
  state: "Estado",
  city: "Ciudad",
  termsOfUse: "He leído y acepto las",
  terms: "Condiciones de uso",
  policy: "Política de privacidad",
  companyNumber: "Rol Único de Contribuyentes (RUC)",
  companyData: "Completa los datos de su empresa",
  companyName: "Nombre corporativo",
  tradingName: "Nombre comercial",
  continueAsNaturalPerson: "Continuar como persona física",
  plans: "Elige tu plan",
  choosePlan: "Elegir plan",
  choosedPlan: "Seleccionado",
  errorChoosePlan: "Elige un plan",
  errorAsigneePlan: "Hubo un error con el plan.",
  errorCreatePerson: "Ocurrió un error al crear el usuario.",
  termsError: "Hubo un error al cargar el documento",
  validCode: "Introduce un código válido.",
  emailInUse: "Email en uso",
  codeExpires: "El código caduca en:",
  sendCode: "Reenviar codigo",
  sendedCode: "Codigo reenviado!",
  codeExpired: "Código caducado",
  professional_number: "Número del consejo de clase",
  expertise: "Pericia",
  instagram: "Instagram",
  notOperational: "¡Ups! Fix it aún no funciona en su región.",
  noOperationalDescription:
    "Contacta con nuestro servicio de atención al cliente y descubre cómo llevar Fix it a ti",
  contactUs: "Contacta con nosotros",
  registerCompanyAddress: "Registrar dirección de servicio",
  finishLater: "Elegir más tarde",
  fillManagerData: "Complete los datos de su gerente",
  confirm: "Confirmar",
};

const login: ITranslateLogin = {
  email: "E-mail",
  password: "Contraseña",
  forgotPassword: "Olvide mi contraseña",
  login: "Acceso",
  register: "Registrarse",
  submit: "Iniciar sesión",
  dontHaveAccount: "¿No tienes una cuenta?",
  recover_password: "Recuperación de contraseña",
  send_code: "Enviar código de reinicio",
  new_password: "Nueva contrasenã",
  new_password_confirmation: "Confirmar nueva contraseña",
  change_password: "Cambiar contrasenã",
  password_changed: "Password Changed Successfuly!",
  starting: "Inicializando",
};

const tips: ITranslateTips = {
  title: "Nuevas características",
  create: "Nueva tip",
  edit: "Editar",
  delete: "Deletar",
  createButton: "Crear tip",
  editButton: "Guardar",
  name: "Nombre",
  users: "Usuarios",
  type: "Tipo",
  description: "Descripción",
  descriptionError: "Ocurrió un error al intentar cargar los datos.",
  success: "Tip creada con éxito",
  error: "Ocurrió un error al crear la tip.",
  page: "Página",
  updateButton: "Actualizar",
  updateError: "Ocurrió un error al editar la tip.",
  changeStateError: "Ocurrió un error al intentar actualizar la tip.",
  specificUrl: "Parámetro",
  activateTip: "Activar tip",
  cancel: "Cancelar",
  confirm: "Confirmar",
  deleteTip: "Borrar tip",
  deleteError: "Se produjo un error al intentar eliminar la tip.",
  globalWarning:
    "Identificamos que ya hay un tip global activado, ¿quieres desactivarlo para activar este?",
  pageWarning:
    "Identificamos que ya hay un tip para esta página activado, ¿quieres desactivarlo para activar este?",
  deleteWarning:
    "Este consejo sigue activo, ¿estás seguro de que quieres eliminarlo?",
  new: "Noticias",
  dontShowAgain: "No mostrar de nuevo",
};

const creators: ITranslateCreators = {
  solutions: "Gestionar soluciones",
  make: "Producir",
  addFile: "Agregar archivo",
  side: "Lado",
  size: "Tamaño",
  part: "Parte",
};

const global: ITranslateGlobal = {
  underConstruction: "Disponible pronto",
  solutions: "Soluciones",
  situation: "Situación",
  undo: "Deshacer",
  regions: {
    southeast: "Sudeste",
    south: "Sul",
    north: "Norte",
    northEast: "Nordeste",
    midwest: "Centro-Oeste",
    international: "Internacional",
  },
  customerSituation: {
    effected: "Venta efectuada",
    implementation: "En fase de ejecución",
    operational: "En la operación",
    training: "Entrenando",
    onboarding: "Onboarding",
  },
  licensees: {
    header: "Autorizados",
    create: "Crear Autorizado",
    edit: "Editar autorizado",
    field: {
      document_number: "CNPJ/CPF",
      email: "E-mail",
      phone: "Telefono",
      instagram: "Instagram",
      is_public: "Público",
      natural_person: "Persona",
      legal_entity: "Empresa",
      cpfMask: "999.999.999-99",
      cnpjMask: "99.999.999/9999-99",
      manager_name: "Gerente",
      is_test: "Autorizado prueba",
    },
  },
  designers: {
    header: "Designers",
    create: "Crear Designer",
    edit: "Editar designer",
  },
  configs: {
    header: "Configurações gerais",
    createUserTerms: "Novos termos de uso",
    solutionFields: "Áreas das soluções",
    solutionCategories: "Categorias de",
    createSolutionField: "Nova área",
    createSolutionCategory: "Nova categoria",
    editSolutionField: "Editar ",
    editSolutionCategory: "Editar ",
    docs: {
      header: "Documentos - contratos {{contract}}",
      label:
        "Estos documentos están disponibles en toda la aplicación por términos de contratos como términos de uso o política de privacidad.",
    },
    printers: {
      header: "Impresoras aprobadas",
      edit: "Editar impresora",
      create: "Crear impresora",
      firstStep:
        "En este primer paso, debe tener instalado el controlador de la impresora (Mac o Windows), por lo que, con la impresora conectada a un puerto USB, haga clic en el botón a continuación.",
      secondStep:
        "En el segundo paso, realizaremos algunos movimientos en la impresora, completamente fuera de línea, es decir, este proceso funciona incluso cuando está desconectada de Internet. Lo que probará la comunicación con el dispositivo.",
      conclusion:
        "Ahora, ya está todo listo para comenzar a imprimir. Siéntase libre de calentar la mesa y la extrusora aquí o directamente desde la impresora.",
      heatBed: "Calentar la cama 65ºC",
      heatExtruder: "Calentar la extrusora 210ºC",
      configure: "Configurar impresora",
      connect: "Conectar impresora",
      notfound: "¿No encontraste tu impresora?",
      manualSearch: "Busca aquí",
      test: "Iniciar prueba",
      baudRateChangeSucess: "Baud Rate cambiada con éxito",
      changeBaudRateTo: "Cambiar Baud Rate a",
    },
    users: {
      header: "Usuarios internos",
      edit: "Editar usuario",
      create: "Crear usuario",
    },
    tips: {
      header: "Nuevas características",
    },
  },
  novalue: "Limpio",
  status: {
    label: "Registros activos",
    active: "Activos",
    deactive: "Desactivos",
    registered: "Registrados",
  },
  sides: {
    ESQ: "Izquierda",
    DIR: "Derecha",
    UNI: "Único",
  },
  roles: {
    LICENSEE: "Cliente",
    FIXITER: "Fixiter",
    CREATOR: "Diseñadores de productos",
    ADMIN: "Administrador",
    SAF: "Asistencia (SAF)",
    EMPLOYEE: "Usuario",
  },
  password: {
    password: "Contraseña actual",
    password_confirmation: "Confirmar la nueva contraseña",
    new_password: "Nueva contraseña",
    save_changes: "Guardar ediciones",
    change_password: "Cambia tu contraseña",
  },
};

const dashboard: ITranslateDashboard = {
  header: "Informes",
  requestsDay: "Pedidos hoy",
  requestsMonth: "Pedidos en el mes",
  requestsSevenDays: "Pedidos en los últimos 7 días",
  requestsYear: "Pedidos en",
  revenueYear: "Ingressos en",
  clickSevenDays: "Clics en el sitio web",
  soon: "PRONTO",
  nps: "NPS",
  months: {
    jan: "Ene",
    feb: "Feb",
    mar: "Mar",
    apr: "Abr",
    may: "Mayo",
    jun: "Jun",
    jul: "Jul",
    aug: "Ago",
    sep: "Sept",
    oct: "Oct",
    nov: "Nov",
    dec: "Dic",
  },
  newPatients: "Nuevos pacientes",
  bill: "Factura",
  filamentUse: "Consumo de filamento",
  filamentInfo: "Promedio calculado en base a pedidos estandarizados",
  error: "No se pudieron cargar las información de la dashboard",
  caseIncidence: "Incidencia de casos",
  mostAskedSolutions: "Soluciones más solicitadas",
  patientsInfo: "Pacientes registrados durante el período seleccionado",
  month: "Mes",
  apply: "Aplicar",
  period: "Período",
};

const solutions: ITranslateSolutions = {
  header: "Soluciones Fix it",
  select: "Seleccione",
  whichSize: "¿QUE TAMAÑO?",
  aplicationVideo: "Vídeo de aplicación",
  categories: {
    header: "¿Qué categoría de solución desea?",
  },
  solutions: {
    header: "Elige una solución",
  },
  download: "Descargar",
  unique_part: "Unica",
  image_upload_title: "Actualizar imagen",
  image_upload_subtitle: "Imagen de visualización de la solución",
  save_new_image: "Guardar nueva imagen",
  edit_image: "Cambiar la imagen de la solución",
  download_file_enable: "Habilitar",
  download_file_upload_title: "Actualizar archivo",
  download_file_upload_subtitle: "Archivo para download da la solución",
  custom_update_title: "Actualizar personalizada",
  custom_update_subtitle: "Datos de la solución personaliza",
  standard_update_title: "Actualizar estandarizada",
  size_patterns: "Estándares de tamaño",
  standard_update_subtitle: "Datos de la solución estandarizada",
  is_custom: "Solución para personalización",
  is_standard: "Solución para estandarización",
  is_download: "Solución para download",
  name: "Nombre de la solución",
  edit: "Editar solución",
  category: "Categoría",
  field: "Área",
  form_type: "Tipo de formulario",
  suggested_price: "Precio sugerido",
  side: "Lado",
  number_sides: "Lados",
  updateButton: "Guardar datos",
  number_parts: "Partes",
  part: "Parte",
  number_measures: "Medidas",
  link_guide: "URL de la guía de medidas",
};

const success: ITranslateSuccess = {
  header: "Éxito del cliente",
};

const orders: ITranslateOrders = {
  financial_status: {
    order_status: "Estado del pedido",
    paid: "Pagado",
    not_paid: "Facturado",
    courtesy: "Descontado",
    success: "Estado del pedido actualizado con éxito.",
    error: "Ocurrió un error al intentar actualizar el estado del pedido.",
  },
  confirmation: {
    service_number: "Número de servicio",
    confirm: "Confirmar detalles del pedido",
    patient: "Paciente",
    years: "años",
    profissional: "Profesional",
    partner: "El socio",
    solution: "Solución",
    custom: "Personalizado",
    standard: "Estandarizado",
    measures: "Medidas",
    bodyPart: "Parte del cuerpo",
    injuryType: "Tipo de lesión",
    surgeryDate: "Fecha de la cirugía",
    target: "A qué se debe apuntar",
    markAsUrgency: "Marcar como urgente",
    urgencyDetails:
      "Las solicitudes con urgencia injustificada se cobrarán el doble.",
    totalValue: "Valor total",
    justification: "Justificación",
    responsabilityConfirmation:
      "Declaro que los datos facilitados son de mi responsabilidad y que son todos correctos.",
    cancel: "Cancelar",
    confirmAndFinish: "Confirmar y finalizar",
  },
  urgency: {
    requested: "Petición de urgência para o pedido",
    requestedInfo: "Se ha realizado una solicitud de urgencia para el pedido.",
    requestedApprove: "¿quieres aprobarlo?",
    approve: "Aprobar",
    refuse: "Rechazar",
    popoverRequested: "Urgencia solicitada",
    popoverApproved: "Urgencia aprobada",
    popoverNonJustified: "Urgencia no justificada",
    additionalValueJustification:
      "Los pedidos personalizados tendrán un incremento de {{value}} {{currency}}, en caso de urgencia {{doubleValue}} {{currency}}",
  },
  actions: {
    new: "NUEVO",
    edit: "EDITAR",
    save: "GUARDAR",
    close: "CERRAR",
    discart: "DESCARTAR",
    upload: "UPLOAD",
  },
  pending_info: {
    text: "Información destacada",
  },
  patient_section: {
    search: {
      error: "Ocurrió un error al intentar cargar los pacientes.",
    },
    empty_title: "Datos del paciente",
    empty_subtitle: "Edad, peso, teléfono...",
  },
  partner_section: {
    search: {
      error: "Ocurrió un error al intentar cargar los profesionales.",
    },
    empty_title: "Datos del socio",
    empty_subtitle: "teléfono, email...",
    entities: {
      natural_person: "PERSONA FÍSICA",
      legal_entity: "PERSONA JURÍDICA",
    },
  },
  solution_section: {
    empty_title: "FINALIZAR Y ENVIAR",
    empty_subtitle: "Describa el producto: tipo, color, medidas...",
    measurement_guide: "Consultar guía de medidas",
  },
  exam_section: {
    empty_title: "DATOS DE EVALUACIÓN",
    empty_subtitle: "Profesional, diagnóstico, miembro...",
    professional_label: "Datos del profesional responsable",
  },
  planit_section: {
    title_template: "PLANIFICACIÓN ",
    empty_title: "PLANIFICACIÓN",
    empty_subtitle: "Profesional, lesiones, expedientes...",
    doctor_subsection_title: "Información del médico/cirujano responsable",
    evaluation_subsection_title: "Evaluación",
    files_subsection_title: "Archivos",
  },
  order_type: {
    is_patient: "PARA MI PACIENTE",
    is_partner: "PARA MI SOCIO",
    is_fixiter: "PARA EL FIXITER",
  },
  create: {
    custom: {
      header: "Orden personalizado",
      header_template: "Orden ",
    },
    standard: {
      header: "Orden estandarizado",
      header_template: "Estandar. ",
    },
    plan: {
      header: "Plan. quirúrgica",
      header_template: "Plan. quirúrgica",
    },
  },
  printChoose: {
    header: "Elige una impresión",
    available_info: "Disponible {{available}} de {{quantity}}",
    standard: "Estándar",
    sides: "Lado",
    save: "Guardar y preparar la impresión",
    prepare: "Preparar la impresión",
    error: "Upsy! Impressión indisponible",
    advice: "Esperando datos",
    waitingchoice: "Esperando elección",
    noprinter: "Por favor, seleccione una impresora",
    useStandardFileOption: "Mantenerse {{print_option}}",
    proceed: "Seguir impremiendo - {{file_choice}}",
    end: "Cerrar impresión - {{file_choice}}",
    endAndSelect: "Cerra y seleccione otra",
    add: "Agregar elección",
    progress: "Muéstrame el progreso",
    demand: "Demanda de impresión - {{file_choice}}",
    waiting: "FixPoint imprimiendo - {{file_choice}}",
    notStarted: "Esperando FixPoint",
    completed: "Impresión completada {{file_choice}}",
    printControl: {
      header: "Eligir parte",
      selectStream: "Imprimir {{print_option}}",
      completed: "Impresión completada {{print_option}}",
      tryagain: "Intentar nuevamente {{print_option}}",
      advice: "Esperando datos",
      error: "No se puede comenzar a imprimir",
      empty: "No hay part a imprimir",
      print: "Configurar impresión",
      printing: "Imprimiendo en curso {{print_option}}",
      preHeatingBedAndExtruder: "Calentando la cama y la extrusora",
    },
  },
  creator: "Designer",
  licensee: "Cliente",
  print: "Imprimir",
  download: "Opciones de descarga",
  view: "Détails",
  printHistory: "Historial de impresión",
  printManagement: "Gestión de impresión",
  printStarting: "Tu impresión se está iniciando,",
  downloadStarting: "Tu descargar se está iniciando,",
  printingSuccess: "éxito de operación!",
  clickToDownload: "No empezado? Click aquí para descargar.",
  printingData: "Datos de impresión",
  printingTime: "Tiempo",
  materialCost: "Costo",
  materialWeight: "Peso",
  custom_production_update_title: "Envio de personalización",
  custom_production_update_subtitle: "Datos de producción",
  start_development: "Iniciar el desarrollo",
  call_review: "Iniciar revisión",
  is_cancelled: "Pedido cancelado",
  commit_review: "Confirmar revisión",
  header: "Pedidos",
  as_provider: "Demandas FixPoint",
  date: "Fecha",
  patient: "Paciente",
  partner: "Socio",
  solution: "Solución",
  type: "Tipo",
  category: "Categoría",
  quantity: "Cantidad",
  printer: "Impresora",
  state: "Situación",
  price: "Valor Total",
  total: "Total ({{order_type}})",
  files: "Archivos",
  selectPrinter: "Seleccionar una impresora...",
  sendToPrinter: "Enviar a la impresora",
  conclude: "Concluir",
  didNotFindPrinter: "No encuentra su impresora en la lista?",
  didNotFoundNavigator: "¡Parece que tu navegador no es Chrome!",
  chromeDownload1:
    "¡Para el mejor uso de los sistemas FixIt, le recomendamos que lo use!",
  chromeDownload2: "O puedes descargarlo aquí:",
  printComplete: "Está completa la impresión?",
  clickToProceed: "Haga clic para continuar",
  patientNotSet: "No informado",
  status: {
    processing: {
      value: "processing",
      optionText: "Procesando",
    },
    development: {
      value: "development",
      optionText: "Desarrollando",
    },
    review: {
      value: "review",
      optionText: "En revisión",
    },
    cancelled: {
      value: "cancelled",
      optionText: "Cancelado",
    },
    completed: {
      value: "completed",
      optionText: "Terminado",
    },
    expired: {
      value: "expired",
      optionText: "Expirado",
    },
  },
  fields: {
    status: "Status",
  },
  cancelConfirmation: "¿Está seguro de que desea cancelar el pedido?",
  qtd: "CTD",
  order_provider: {
    requester: "Solicitante",
    order_data: "Datos de los pedidos",
    company_name: "Nombre de la empresa",
    fantasy_name: "Nombre de fantasía",
    responsible: "Responsable",
    location: "Ubicación",
  },
  filter: {
    sublicensee: "Sublicenciatarios",
  },
  planit: {
    submit_file_upload: "Enviar imagen",
    file_upload: "Carga de imagen",
  },
  deliveryTime: "El tiempo de entrega",
};

const profile: ITranslateProfile = {
  header: "Mi unidad",
  unit: "Unidad",
  email: "Email",
  state: "Situación",
  region: "Región",
  managerName: "Nombre del gerente",
  phone: "Telefone",
  instagram: "Instagram",
  editProfile: "Editar perfil",
  changePassword: "Cambiar contraseña",
  printers: "Impressoras",
  addPrinter: "Agregar impresora",
};

const sidebar: ITranslateSidebar = {
  reports: "Informes",
  solutions: "Soluciones",
  requests: "Pedidos",
  files: "Archivos",
  success: "Éxito",
  designers: "Designers",
  licensees: "Licenciados",
  config: "Config.",
  signout: "Salir",
  prices: "Precios",
  plans: "Planes",
  onboarding: "Onboarding",
  courses: "Cursos",
  financial: "Financiero"
};

const navMenu: ITranslateNavMenu = {
  reports: "Informes",
  solutions: "Soluciones",
  requests: "Pedidos",
  files: "Archivos",
  success: "Éxito",
};

const topbar: ITranslateTopBar = {
  coinFix: {
    start: "Tú tienes",
    end: "FixCoin",
    buy: "Comprar",
    balance: "Saldo disponible",
    plural: "FixCoins",
  },
  notification: "Uhul, tú tienes {{ quantity }} notificaciones!",
  editProfile: "Editar perfil",
  signout: "Salir",
  noprinter: "Sin impresora",
  fixpoint: "FixPoint",
  change_password: "Cambia la contraseña",
  my_users: "Mis Usuarios",
  filamentData: "Datos Del Filamento",
  addPerson: "Añadir datos personales",
};

const dropZone: ITranslateDropZone = {
  dragActive: "Haga clic o arrastre para agregar",
  error: "Archivo no soportado",
  success: "Agregar los archivos aqui",
};

const fileList: ITranslateFileList = {
  delete: "Excluir",
};

const goBackBtn: ITranslateGoBackBtn = {
  back: "Volver",
};

const optionsBtn: ITranslateOptionsBtn = {
  successMessage: "Pedido cancelado con éxito",
  evaluation: "Acceder a la evaluación",
  problem: "Tuve un problema",
  cancel: "Cancelar pedido",
  printHistory: "Historial de impresión",
};

const orderBox: ITranslateOrderBox = {
  date: "Fecha",
  patient: "Paciente",
  solution: "Solución",
  category: "Categoría",
  quantity: "Cantidad",
  printer: "Impresora",
  state: "Situación",
  price: "Valor Total",
  files: "Archivos",
};

const searchBar: ITranslateSearchBar = {
  placeholder: "Buscar algo...",
};

const surgicalPlanning: ITranslateSurgicalPlanning = {
  personalize: "Planificación quirúrgica",
  nullTargetField: "Informar a qué se debe apuntar",
  segmentation: "Segmentación",
  doctorInformation: "Información del médico/Cirujano responsable",
  partOfTheBody: "Parte del cuerpo",
  lesionType: "Tipo de lésion",
  whatShouldBeSegmented: "Que se debe segmentar",
  surgeryDate: "Fecha de la cirugía",
};

const standardSolution: ITranslateStandardSolution = {
  buy: "Estandarizado",
  request: "Solicitar",
  size: "Talla",
  side: "Lado",
  quantity: "Cantidad",
  printingInfo: "Informaciónes de impresión",
  selectPrinter: "Impresora",
};

const customSolution: ITranslateCustomSolution = {
  buy: "Personalizado",
  updated_at: "Actualizado a",
  updated_at_suffix: "min.",
  noFilePrice: "Esperando los datos del formulario para el cálculo del precio",
  noSelectedPrinter: "Ninguna impresora seleccionada",
  noStandardFile: "No hay ningún archivo disponible para la configuración dada",
  totalPrice: "Total:",
  previous: "Anterior",
  next: "Seguinte",
  successToast: "Pedido realizado con éxito!",
  errorToast: "Erro al realizar el pedido! Detalles:",
  sendOrder: "Eviar pedido",
  personalize: "Personalizar",
  selectPrinter: "Seleccione la impressora",
  step: "Paso",
  of: "de",
  patientData: "Información del paciente",
  fullName: "Nombre completo",
  company_name: "Nombre social",
  professional_number: "Número de licencia profesional",
  cpf: "Numero del Documento",
  cnpj: "Numero de compañia",
  cep: "Código Postal",
  cepMask: "99999-999",
  contact: "Contacto",
  contactMask: "(99) 99999-9999",
  measuresError: "Por favor, agregue las medidas",
  nameError: "Escreva o nome completo del paciente!",
  professionalNameError: "Ingrese el nombre del profesional.",
  diagnosisError: "Describe el diagnóstico realizado.",
  age: "Edad",
  months: "Meses",
  weight: "Peso (kg)",
  height: "Altura (cm)",
  responsibleName: "Nombre del responsable",
  responsiblePlaceholder: "Si es menor de edad o discapacitado",
  phone: "Teléfone",
  phonePlaceholder: "(00) 00000-0000",
  email: "E-mail",
  emailPlaceholder: "ejemplo@usefixit.com",
  evaluation: "Evaluación",
  hasIndication: "Tiene indicacion",
  previousUse: "Uso previo de órtesis",
  professionalName: "Nombre del profisional que evaluó",
  occupation: "Área de actuación",
  diagnosis: "Diagnóstico",
  limb: "Miembro",
  previousOrthesis: "Ortesis utilizada anteriormente",
  previousOrthesisPlaceholder: "Ex.: Yeso, placa termoplástica, etc...",
  finishingOrder: "Finalizando orden",
  measures: "Medidas",
  measuresGuide: "Consultar guía de medidas",
  filamentColor: "Color del filamento",
  observations: "Comentarios",
  observationsPlaceholder:
    "Ex.: Membro hinchado, medidas del miembro contralateral, etc...",
  colors: {
    aluminum: "Aluminio",
    yellow: "Amarillo",
    skyBlue: "Azul Cielo",
    cobaltBlue: "Azul Cobalto",
    darkBlue: "Azul Oscuro",
    metalicBlue: "Azul Metalico",
    oceanBlue: "Azul Oceano",
    white: "Blanco",
    bronze: "Bronce",
    gray: "Gris",
    golden: "Dorado",
    silver: "Plata",
    black: "Negro",
    orange: "Naranja",
    oilOrange: "Naranja nacarado",
    lilac: "Lila",
    ivory: "Marfil",
    natural: "Natural",
    pistachio: "Pistacho",
    pink: "Rosado",
    purple: "Púrpura",
    darkPurple: "Púrpura Oscuro",
    metalicPurple: "Púrpura Metalico",
    green: "Verde",
    darkGreen: "Verde Oscuro",
    forestGreen: "Verde Bosque",
    tiffanyGreen: "Verde Tiffany",
    red: "Rojo",
  },
  jobs: {
    physiotherapist: {
      value: "physiotherapist",
      optionText: "Fisioterapeuta",
    },
    orthopedist: {
      value: "orthopedist",
      optionText: "Ortopedista",
    },
    occupationalTherapist: {
      value: "occupationalTherapist",
      optionText: "Terapeuta Ocupacional",
    },
    other: {
      value: "other",
      optionText: "Otro",
    },
  },
  sides: {
    left: "Izquierda",
    right: "Derecha",
    unique: "Único",
  },
  diagnostic: {
    newDiagnosisLabel: "¿No encontró un diagnóstico?",
    diagnosisRegisters: "Registrar aquí",
    newDiagnosisModalLabel: "Registrar diagnóstico",
    name: "Nombre",
    icd: "CID",
    register: "Registro",
  },
  gender: {
    gender: "Género",
    male: { optionText: "Masculino", value: "male" },
    female: { optionText: "Femenino", value: "female" },
  },
};

const evaluation: ITranslateEvaluation = {
  patient: "Paciente",
  name: "Nombre",
  age: "Edad",
  months: "Meses",
  weight: "Peso",
  height: "Altura",
  evaluation: "Evaluación",
  diagnosis: "Diagnóstico",
  previousOrthesis: "Uso previo de ortesis",
  indication: "Indicación",
  observations: "Observaciones",
  header: "Evaluación",
  requirements: "Requisitos",
  printer: "Impresora",
  side: "Lado",
  bodyPart: "Parte del cuerpo",
  segmentaion: "A qué se debe apuntar",
  injuryType: "Tipo de lesión",
  surgeryDate: "Fecha de la cirugía",
  urgency: "Urgencia",
  urgencyCause: "Justificación de la urgencia",
  urgencyNotComprooved: "Urgencia no comprobada",
  measure: "Medida",
  planning: "Planificación",
  professional: "Profesional",
  images: "Imágenes",
  files: "Archivos",
};

const form_errors: ITranslateForm_Errors = {
  alert: "Revisa los campos con error.",
  required: "${path} El campo es obligatorio",
  minRequired: "${path} Obligatorio",
  matchPassword: "${path} Las contraseñas no coinciden",
  validEmail: "${path} Introduzca un email válido",
  validCountry: "${path} Seleccione un país",
  invalidValue: "${path} Introduce un valor valido",
  passwordLength: "${path} La contraseña debe tener al menos 8 caracteres",
  passwordCharacters:
    "${path} La contraseña debe contener al menos una letra mayúscula, un número y un carácter especial",
};

const disabled_user: ITranslateDisabled_user = {
  principal: "Su acceso está deshabilitado debido a asuntos pendientes",
  secundary: "Póngase en contacto con SAF",
  disabledFixiter: "¡Tu plan ha expirado!",
  disabledFixiterSecondary: "Póngase en contacto con SAF para renovar",
};

const financial: ITranslateFinancial = {
  header: "Financiero",
  prices: {
    header: "Precios",
    create: "Crear precio",
    edit: "Editar precio",
    update: "Actualizar",
    save: "Guardar",
    country: {
      default: "TODOS",
    },
    confirm: {
      deactivate: "¿Seguro que quieres desactivar este precio?",
      activate: "¿Seguro que quieres activar este precio?",
    },
    field: {
      country: "País",
      order_type: "Tipo de orden",
      solution_type: "Tipo de solución",
      currency: "Divisa",
      file_price: "Precio",
      plan_name: "Nombre del plano",
      solution: "Solución",
      trial_period: "Validez",
      numberOfSolutions: "Nº de soluciones disponibles",
      active: "Activo",
      inactive: "Inactivo",
    },
    solution_type: {
      standard: "Estándar",
      custom: "Personaliado",
      plan: "Pl. quirúrgica",
      default: "Todos",
    },
    order_type: {
      is_patient: "B2C",
      is_partner: "B2B",
      is_fixiter: "FIXITER",
    },
    plan_type: {
      demo: "Demostración",
      advanced: "Avanzado",
      basic: "básico",
      old_signatures: "Firma antigua",
    },
  },
  plans: {
    confirm: {
      deactivate: "¿Seguro que quieres desactivar este plan?",
      activate: "¿Seguro que quieres activar este plan?",
    },
    financial: {
      free: "¿Está seguro de que desea configurar el plan como gratuito?",
      paid: "¿Está seguro de que desea configurar el plan como pago?",
    },
  },
  situation: {
    paid: "Pagado",
    not_paid: "Facturado",
    courtesy: "Gratis",
  },
  error: {
    get_prices: "¡Ocurrió un error al intentar cargar los precios!",
    get_signature: "Aún no tienes una suscripción, ¡contacta con SAF!",
  },
};

const error: ITranslateErrorMessages = {
  printings: {
    completed: "Impresión ya hecho",
    quantity: "Alcanzó el límite de impresión",
    missingpart: "Falta parte de la impresión. Contact SAF service",
  },
};

const printer: ITranslatePrinter = {
  setup: "Click para continuar",
  ready: "Listo para imprimir",
  erase: "Límpialo",
  filament: {
    success: "Configuración de filamento exitosa",
    error: "¡No se pudo configurar el filamento!",
    filamentConfigs: "Configuración de filamentos",
    filamentDescription: "Ingrese los detalles de su compra de filamento para que podamos calcular correctamente el costo de su material.",
    buyingCoin: "Moneda de Compra",
    buyingCost: "Costo de filamento por kilo",
    configure: "Para exposición de dos dados de custo, favor de configurar sus dados de filamento.",
  },
  ipTypingAdvice: "*Ingrese la IP exactamente como aparece en la impresora"
};

const courses: ITranslateCourses = {
  names: {
    fixit: "Fix it tecnología",
    punhofix: "PunhoFix entrenamiento de aplicaciones",
  },
  descriptions: {
    fixit: "¡Aprenda más sobre la Fix it y impresión 3D!",
    punhofix: "¡Aprende a aplicar una de nuestras principales soluciones!",
  },
};

const address: ITranslateAddress = {
  address_options: {
    address: "Dirección",
    edit: "Editar dirección",
    delete: "Eliminar dirección",
    new_address: "Registro dirección",
    create_as: "Registro como:",
    created_as: "Registrado como:",
    save: "Ahorrar",
    att: "Actualizar",
    close: "Cerrar",
    cancel: "Cancelar",
  },
  my_addresses: "Mis direcciones",
  billing_address: "DIRECCIÓN DE ENVIO",
  deliver_address: "DIRECCIÓN DE ENTREGA",
  company_address: "DIRECCIÓN DE COMPAÑÍA",
  address: "Lugar público",
  cep: "Código postal",
  number: "Número",
  complement: "Complementar",
  district: "Distrito",
  city: "Ciudad",
  state: "Estado",
};

const person: ITranslatePerson = {
  manager: "Gerente",
  user: "Usuario",
  edit_personal_data: "Editar datos personales",
  delete_user: "Borrar datos personales",
  name: "Nombre",
  cpf: "Registro de persona fisica",
  cellphone: "Teléfono celular",
  expertise: "Ocupación",
  city: "Ciudad",
  state: "Estado",
  country: "País",
  create_person: "Ventaja",
  personal_data: "Datos personales",
  save: "Ahorrar",
  error: {
    user_registration_error: "No se puede registrar el usuario! Póngase en contacto con SAF.",
    delete_user: "¡Este usuario no se puede eliminar!",
  },
  success: {
    att_user_data: "¡Datos personales actualizados con éxito!",
    user_deleted_success: "¡Usuario eliminado!",
    user_deleted_error: "¡No se pudo eliminar el usuario!",
  },
  bpRegisterPopover: "Para uso exclusivo de usuarios internos de BP",
  bpRegister: "Registro de BP"
};

const coins: ITranslateCoins = {
  paraguai: {
    coin: "Guaraní",
    country_name: "Paraguay",
  },
  mozambique: {
    coin: "Metical",
    country_name: "Mozambique",
  },
  uruguai: {
    coin: "Peso Uruguayo",
    country_name: "Uruguay",
  },
  swiss: {
    coin: "Franco Suizo",
    country_name: "Suiza",
  },
  europe: {
    coin: "Euro",
    country_name: "Europe",
  },
  argentina: {
    coin: "Peso Argentino",
    country_name: "Argentina",
  },
  el_salvador: {
    coin: "Dolar",
    country_name: "El Salvador",
  },
  venezuela: {
    coin: "Peso Venezolano",
    country_name: "Venezuela",
  },
  brazil: {
    coin: "Real",
    country_name: "Brazil",
  },
  moneySymbol: "$",
};

const es: ILanguageMessages = {
  translations: {
    transactions,
    language,
    noInfo,
    userProfile,
    userRegistration,
    login,
    tips,
    creators,
    global,
    dashboard,
    solutions,
    success,
    orders,
    profile,
    sidebar,
    topbar,
    navMenu,
    dropZone,
    fileList,
    goBackBtn,
    optionsBtn,
    orderBox,
    searchBar,
    surgicalPlanning,
    standardSolution,
    customSolution,
    evaluation,
    form_errors,
    disabled_user,
    financial,
    fixiterOnboarding,
    error,
    printer,
    courses,
    address,
    person,
    coins,
    bills,
  },
};

const messages = { es };

export { messages };
