import { i18n } from "../../../../../../localization/i18n";
import { Solution } from "../../../../CreateOrder/dtos";

interface StandardOrderInfosProps {
  solution: Solution;
}

export function StandardOrderInfos({ solution }: StandardOrderInfosProps) {
  return (
    <div className="order_resume">

      <span className="item">
        <strong>{`${i18n.t("orders.confirmation.solution")}`}: </strong>
        {solution?.name} - {`${i18n.t("orders.confirmation.standard")}`}
      </span>
    </div>
  );
}
