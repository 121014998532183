import styled from "styled-components";
import global from "../../../../global";

interface IAlignItems {
  alignBox?: boolean;
}

export const Container = styled.div<IAlignItems>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    padding: 1.5rem 0 0 0;
`

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 2.35rem;
    justify-content: center;
    align-items: center;

    @media (min-width: 1550px) {
        grid-template-columns: 1fr 1fr;
    }

    @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
        grid-template-columns: 1fr;
    }
`

export const SelectClosurePeriod = styled.div`
    background: white;
    box-shadow: 1px 5px 10px rgb(0 0 0 / 25%);
    display: flex;
    gap: 1rem;
    padding: 0.2rem;
    border-radius: 20px;
    position: relative;
    right: 37.5px;
`
