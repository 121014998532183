import { AssociatesNotFoundContainer, AssociatesAndMapContainer, AssociatesContent, BackgroundContainer, ButtonContainer, Container, FormContainer, InputContainer, MapContainer } from "./style";
import { OutlinedInput } from "../../../../components/OutlinedInput";
import { useEffect, useState } from "react";
import { OutlinedButton } from "../../../../components/OutlinedButton";
import AssociatesItem from "./components/AssociatesItem";
import GoogleMap from "./components/GoogleMap";
import { showToast } from "../../../../components/CustomToast";
import { i18n } from "../../../../localization/i18n";

import * as Yup from "yup";
import { typesErrors } from "../../../../utils/validateFormFields/yupErrors";
import validateFormFields from "../../../../utils/validateFormFields";
import api from "../../../../services/api";

interface IAssociatesProps {
  id: string;
  name: string;
  phone: string;
  instagram: string;
  profile_picture: string;
  professional_number: string;
  distance: string;
  duration: string;
  rating: number;
  latLong: {
    lat: number;
    lng: number;
    text: string;
  }
}

interface ILeadLocation {
  lat: number,
  lng: number
}

export default function FindNearestFixiter() {
  const [errors, setErrors] = useState({} as any);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('')
  const [cep, setCep] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [nearbyAssociates, setNearbyAssociates] = useState<IAssociatesProps[]>([])
  const [centerCoordinates, setCenterCoordinates] = useState({ lat: 0, lng: 0 })
  const [blockedAfterSearch, setBlockedAfterSearch] = useState(false)
  const [leadLocation, setLeadLocation] = useState<ILeadLocation>()
  const [needCep, setNeedCep] = useState(true)

  /*

  const [valueIndex, setValueIndex] = useState(0);
  const referenceValues = [10, 25, 50, 100, 200, 500];
  const denormalizeValue = (index: number) => referenceValues[index];
  const selectedValue = denormalizeValue(valueIndex);
  const marks =  referenceValues.map((value, index) => ({
    value: index,
    label: `${String(value)} km`,
  }))


  const [distanceRange, setDistanceRange] = useState(10)
  const [distanceOptions, setDistanceOptions] = useState({
    options: [
 r    { value: 1, label: '10 km' },
      { value: 2, label: '25 km' },
      { value: 3, label: '50 km' },
      { value: 4, label: '100 km' },
      { value: 5, label: '200 km' },
    ],
    max: 5
  })

  */


  async function handleSearchAssociates() {
    setLoading(true)
    const body = {
      name,
      phone_number: phoneNumber,
      email,
      cep,
      coordinates: leadLocation
    }

    const yup_validation = Yup.object().shape({
      name: Yup.string().required(typesErrors.required),
      phone_number: Yup.string().required(typesErrors.required),
      email: Yup.string().required(typesErrors.required),
      cep: Yup.string().test(
        'required-if-needCep',
        typesErrors.required,
        (value) => {
          if (needCep) {
            return !!value;
          }
          return true;
        }
      )
    })

    const validation = await validateFormFields(body, yup_validation, {
      name: '',
      phone_number: '',
      email: '',
      cep: ''
    })

    if (validation.status === 400) {
      setLoading(false)
      setErrors(validation.errors);
      showToast({
        type: "error",
        message: i18n.t("form_errors.alert"),
      });

      return;
    }

    setErrors({});

    try {
      await api.post('patients-leads/find-nearby-fixiters', body).then((response) => {
        console.log(response.data)
        setNearbyAssociates(response.data.nearby)
        setCenterCoordinates(response.data.center)
        localStorage.setItem('@FixitApp:Lead', response.data.lead.id)
      })

      setLoading(false)
      setBlockedAfterSearch(true)

    } catch (error) {
      showToast({
        type: "error",
        message: i18n.t("Não foi possivel buscar os associados mais próximos de você. Tente novamente mais tarde."),
      });
      setLoading(false)
      setBlockedAfterSearch(true)
    }

  }

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setNeedCep(false)
        setLeadLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        })
      })
    } else {
      setNeedCep(true)
    }
  }, [])


  /*

   useEffect(() => {
     if (window.innerWidth <= 1000) {
       setDistanceOptions({
         options: [
           { value: 10, label: '10 km' },
           { value: 25, label: '25 km' },
           { value: 50, label: '50 km' },
           { value: 100, label: '100 km' },
         ],
         max: 5,
       })
     } else {
       setDistanceOptions({
         options: [
           { value: 1, label: '10 km' },
           { value: 2, label: '25 km' },
           { value: 3, label: '50 km' },
           { value: 4, label: '100 km' },
           { value: 5, label: '200 km' },
         ],
         max: 5,
       })
     }
   }, [window.innerWidth])

   */



  return (
    <BackgroundContainer>
      <Container>
        <FormContainer onSubmit={(event) => {
          event.preventDefault()
          handleSearchAssociates()
        }}>
          <InputContainer>
            <OutlinedInput readOnly={loading} error={errors?.name} handleChange={(event) => setName(event)} inputName={"name"} label={"Nome"} value={name} />
            <OutlinedInput readOnly={loading} error={errors?.phone_number} mask="(99) 99999-9999" handleChange={(event) => setPhoneNumber(event)} inputName={"phoneNumber"} label={"Numero de telefone"} value={phoneNumber} />
            <OutlinedInput readOnly={loading} error={errors?.email} handleChange={(event) => setEmail(event)} inputName={"email"} label={"E-mail"} value={email} />
            {needCep &&
              <OutlinedInput readOnly={loading} error={errors?.cep} mask="99999-999" handleChange={(event) => setCep(event)} inputName={"cep"} label={"CEP"} value={cep} />
            }
          </InputContainer>

          {/*
          <SliderContainer>
            <Slider
              aria-label="Distância"
              valueLabelDisplay="on"
              valueLabelFormat={(index) => denormalizeValue(index)}
              step={1}
              min={0}
              max={referenceValues.length-1}
              value={valueIndex}
              marks={marks}
              color="secondary"
              onChange={(event, newValue) => setValueIndex(newValue as number)}
            />
          </SliderContainer>
          */}

          <ButtonContainer>
            <OutlinedButton disabled={blockedAfterSearch} type="submit" text="Buscar" buttonWidth={200} loading={loading} />
          </ButtonContainer>

          {nearbyAssociates && nearbyAssociates.length > 0 && (
            <AssociatesAndMapContainer>

              <MapContainer>
                <GoogleMap associatesData={nearbyAssociates} referential={
                  {
                    center: centerCoordinates,
                    zoom: 7,
                  }
                } />
              </MapContainer>

              <AssociatesContent>
                {nearbyAssociates.map((associate) =>
                  <AssociatesItem
                    id={associate.id}
                    name={associate.name}
                    phone={associate.phone}
                    instagram={associate.instagram}
                    profile_picture={associate.profile_picture}
                    professional_number={associate.professional_number}
                    distance={associate.distance}
                    duration={associate.duration}
                    rating={associate.rating} />
                )}
              </AssociatesContent>

            </AssociatesAndMapContainer>
          )}
          {centerCoordinates.lat !== 0 && centerCoordinates.lng !== 0 && nearbyAssociates.length === 0 && (
            <AssociatesNotFoundContainer>
              <p>{`Não encontramos nenhum licenciado em um raio de 500km da sua localização.`}</p>
            </AssociatesNotFoundContainer>
          )
          }
        </FormContainer>


      </Container>

    </BackgroundContainer>
  )
}
