import { useEffect, useMemo, useState } from "react";
import { ICurrencyInfos, useAuth } from "../../../../hooks/AuthContext";
import { usePrinters } from "../../../../hooks/PrintersContext";
import { CustomSolutionForm, IPrice, Solution } from "../../CreateOrder/dtos";
import { IAddress, Licensee } from "../../../Licensee/dtos";
import api from "../../../../services/api";
import { showToast } from "../../../../components/CustomToast";
import { roles } from "../../../../utils/constants";
import { StandardOrderInfos } from "./components/StandardOrderInfos";
import { Modal } from "../../../../components/Modal";
import { RiCloseFill } from "react-icons/ri";

import { i18n } from "../../../../localization/i18n";

import {
  ConfirmOrderModalContainer,
  Header,
  Content,
  Footer,
} from "./styles";
import { SolidButton } from "../../../../components/SolidButton";
import { OutlinedButton } from "../../../../components/OutlinedButton";
import { Separator } from "../../../../components/Topbar/styles";
import { AddressModal } from "../../../../components/AddressModal";
import Dropdown from "../../../Admin/components/Dropdown";
import { InputContainer, InputLine } from "../PrintChoose/styles";
import { OutlinedSelect } from "../../../../components/OutlinedSelect";
import { IStandardFileChoice } from "../../Solutions";

interface ConfirmOrderModalProps {
  open: boolean;
  solution: Solution;
  handleCloseModal: () => void;
  changeOrder: (data: Partial<CustomSolutionForm>) => void;
  onConfirmOrder: () => Promise<any>
  standardFileChoice: IStandardFileChoice | undefined;
  setStandardFileChoice: (value: React.SetStateAction<IStandardFileChoice>) => void;
  currency: ICurrencyInfos | undefined;
  loading: boolean
}

interface IOptions {
  optionText: string;
  value: string;
}

export function StandardOrderConfirmationModal({
  open,
  solution,
  handleCloseModal,
  changeOrder,
  onConfirmOrder,
  standardFileChoice,
  setStandardFileChoice,
  currency,
  loading,
}: ConfirmOrderModalProps) {
  const { user } = useAuth();
  const { userHasPrinter } = usePrinters()

  const [addNewAddress, setAddNewAddress] = useState(false);
  const [userAddresses, setUserAddresses] = useState<IOptions[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<IOptions>();
  const [price, setPrice] = useState<IPrice>()


  async function getPrice() {
    const queryList = [];
    queryList.push(`is_active=${true}`);
    queryList.push(`country=${user.person.licensee.country}`);
    queryList.push(`solution_id=${solution.id}`);

    try {
      const response = await api.get(`/prices/solution?${queryList.join("&")}`);
      setPrice(response.data);
    } catch (error) {
      showToast({
        type: "error",
        message: i18n.t(`financial.error.get_prices`),
      });
    }
  }


  async function getLicenseeAddresses(id: string) {
    try {
      const response = await api.get(`/address/all/${id}`);
      setUserAddresses(
        response.data.map((address: IAddress) => {
          if (address.deliver_address) {
            setSelectedAddress({
              optionText: `${address?.address}, ${address?.number}`,
              value: address.id,
            });
            changeOrder({ delivery_address: address.id });
          }
          return {
            optionText: `${address?.address}, ${address?.number}`,
            value: address.id,
          };
        })
      );
    } catch (error) {
      showToast({
        type: "error",
        message: "Não foi possivel carregar seus endereços.",
      });
    }
  }

  function GrossValueCalc(value: number, quantity: number, aditional: number) {
    const gross = Number(aditional) + value * quantity;
    return gross;
  }

  const formatedTodalValue = useMemo(() => {
    if (price && currency) {
      const valueToFormat =
        GrossValueCalc(price.file_price, 1, 0);

      return valueToFormat.toLocaleString("pt-br", {
        style: "currency",
        currency: currency?.code ?? "BRL",
      });
    }
  }, [
    price,
    currency,
  ]);

  const solutionForm = useMemo(() => {
    return <StandardOrderInfos solution={solution} />;
  }, [solution]);

  useEffect(() => {
    if (open) {
      getPrice()
    }

    if (!userHasPrinter) {
      getLicenseeAddresses(user.person.licensee.id);
    }
  }, [solution, open]);

  return (
    <Modal open={open} modalWidth={500}>
      <ConfirmOrderModalContainer>
        <Header>
          <span className="title">{`${i18n.t(
            "orders.confirmation.confirm"
          )}`}</span>
          <RiCloseFill onClick={handleCloseModal} />
        </Header>

        <Content>
          {solutionForm}

          {!userHasPrinter && (
            <>
              {!addNewAddress ? (
                <div className="address">
                  <strong>Endereço:</strong>
                  <Dropdown
                    options={userAddresses}
                    handleSelect={(selected) => {
                      setSelectedAddress({
                        optionText: selected.optionText,
                        value: selected.value,
                      });
                      changeOrder({ delivery_address: selected.value });
                    }}
                    placeholder={"Selecione o endereço de entrega"}
                    selectedOption={selectedAddress?.optionText}
                  />
                  <div
                    className="newAddress"
                    onClick={() => setAddNewAddress(!addNewAddress)}
                  >
                    <span>Não encontrou seu endereço?</span>
                    <strong>Cadastre um</strong>
                  </div>
                </div>
              ) : (
                <div className="addAddress">
                  <Separator />
                  <AddressModal
                    closeModal={() => setAddNewAddress(!addNewAddress)}
                    licensee={user.person.licensee as unknown as Licensee}
                    getLicenseeAddresses={getLicenseeAddresses}
                  />
                  <Separator />
                </div>
              )}
            </>
          )}

          {
            (
              <>
                <InputLine>
                  <InputContainer>
                    <OutlinedSelect
                      label={`${i18n.t("creators.size")}`}
                      disabled={!solution.standards?.length}
                      options={
                        solution.standards?.map((s) => ({
                          optionText: s.size,
                          value: s.id,
                        })) || []
                      }
                      handleSelect={(selected) => {
                        setStandardFileChoice((prevState: any) => ({
                          ...prevState,
                          standard: {
                            id: selected.value.toString(),
                            size: selected.optionText,
                          },
                        }));
                      }}
                      selectedOption={standardFileChoice?.standard?.size || ""}
                    />
                  </InputContainer>
                </InputLine>

                <InputLine>
                  <InputContainer>
                    <OutlinedSelect
                      label={`${i18n.t("orders.printChoose.sides")}`}
                      options={
                        solution?.sides?.map((side: any) => ({
                          optionText: side,
                          value: side,
                        })) || []
                      }
                      handleSelect={(selected) => {
                        setStandardFileChoice((prevState: any) => ({
                          ...prevState,
                          side: selected.optionText.toString(),
                        }));
                      }}
                      selectedOption={standardFileChoice?.side || ""}
                    />
                  </InputContainer>
                </InputLine>
              </>
            )}

          {!userHasPrinter || user.role === roles.employee ? null : (
            <div className="total">
              <strong>{`${i18n.t("orders.confirmation.totalValue")}`}: </strong>
              <span>{formatedTodalValue}</span>
            </div>
          )}

        </Content>

        <Footer>
          <OutlinedButton
            text={`${i18n.t("orders.confirmation.cancel")}`}
            onClick={handleCloseModal}
          />
          <SolidButton
            text={`${i18n.t("orders.confirmation.confirmAndFinish")}`}
            disabled={
              (!selectedAddress && !userHasPrinter) ||
              loading
            }
            onClick={() => {
              onConfirmOrder()
            }}
          />
        </Footer>
      </ConfirmOrderModalContainer>
    </Modal>
  );
}
