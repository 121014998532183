import { AssociateContainer, Container, DataContainer, FixitLogo, Separator } from "./style";
import logoImage from '../../../../../../assets/imagem_coringa.png'
import { FaInstagram } from "react-icons/fa";
import { MdOutlineWhatsapp } from "react-icons/md";
import api from "../../../../../../services/api";
import { IconContext } from "react-icons";

interface IAssociatesItemProps {
  id: string;
  name: string;
  phone: string;
  instagram: string;
  profile_picture: string;
  professional_number: string;
  distance: string;
  duration: string;
  rating: number;
}
export default function AssociatesItem(associate: IAssociatesItemProps) {

  async function assignLicenseeToALead(licenseeId: string) {
    try {
      await api.put(`patients-leads/update-patient-lead/${localStorage.getItem('@FixitApp:Lead')}`, {
        licensee_id: licenseeId
      })
    } catch (error) {
      console.log(error)
    }
  }

  function formatPhoneNumber(phoneNumber: string) {
    const cleaned = phoneNumber.replace(/\D/g, '');

    if (cleaned.length === 11) {
      return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 7)}-${cleaned.slice(7)}`;
    }
  }

  return (
    <Container>
      <Separator />
      <AssociateContainer>
        <FixitLogo src={associate.profile_picture ? `${process.env.REACT_APP_STORAGE_BASE_URL}${associate.profile_picture}` : logoImage} alt='Fix it Logo' />
        <DataContainer>
          <h3>{associate.name}</h3>

          {
            associate.instagram &&
            <a
              className="instagram"
              href={`https://www.instagram.com/${associate.instagram.slice(1)}`}
              target="_blank"
              style={{ marginLeft: '2px' }}
              rel="noreferrer">
              <FaInstagram size={20} color="var(--fixit)" style={{position:'relative', top:'3px'}} />
            </a>
          }

          <a
            className="whatsapp"
            href={`http://wa.me/+55${associate.phone.replace(/\D/g, '')}`}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              assignLicenseeToALead(associate.id)
            }}
          >
              <h4> Entre em contato </h4>
              <MdOutlineWhatsapp className="icon" size={20} color="var(--active-green)"/>
          </a>

          <p>Contato: {formatPhoneNumber(associate.phone)}</p>
          <p>Distância: {associate.distance}</p>
          <p>Tempo: {associate.duration} </p>
          {
            associate.professional_number &&
            <p className="registerNumber">Nº de registro: {associate.professional_number}</p>
          }

        </DataContainer>
      </AssociateContainer>
    </Container>

  )
}
