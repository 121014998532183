import { Container, FixcoinValue, FixcoinItem } from "./styles";

interface IFixcoinCards {
    price: string;
}

interface IFixcoinItensProps {
    fixcoinPrices: IFixcoinCards[];
    setSelectedValue: (price:string) => void;
    setSelectedCard: (index:number) => void;
    selectedCard: number;
}

export function FixcoinItens({ fixcoinPrices, setSelectedValue, setSelectedCard, selectedCard }: IFixcoinItensProps) {

    return (
        <Container>
            {fixcoinPrices.map((card, index) => {
                return (
                    <FixcoinItem
                        isSelected={index === selectedCard}
                        onClick={() => {
                            setSelectedCard(index);
                            setSelectedValue(card.price);
                        }}
                    >
                        <FixcoinValue>
                            {card.price} FixCoins
                        </FixcoinValue>
                    </FixcoinItem>
                )
            })}
        </Container>
    )
}