import GoogleMapReact from "google-map-react";
import { FaMapPin } from "react-icons/fa";
import { Pin, PinIcon, PinText } from "./styles";

interface IAssociatesProps {
  id: string;
  name: string;
  phone: string;
  instagram: string;
  profile_picture: string;
  professional_number: string;
  distance: string;
  duration: string;
  rating: number;
  latLong: {
    lat: number;
    lng: number;
    text: string;
  }
}


interface IReferentialProps {
  center: {
    lat: number,
    lng: number
  },
  zoom: number
}

interface IMapProps {
  associatesData: IAssociatesProps[]
  referential: IReferentialProps
}

const LocationPin = ({ text }: any) => (
  <Pin>
    <PinIcon>
      <FaMapPin />
    </PinIcon>
    <PinText>{text}</PinText>
  </Pin>
);

export default function GoogleMap(props: IMapProps) {

  function formatPhoneNumber(number: string) {
    return number.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        options={{
          panControl: false,
          mapTypeControl: false,
          scrollwheel: true,
          zoomControl: false,
        }}
        bootstrapURLKeys={{ key: "AIzaSyBUk9IAVJMU_7XYDBA-colAOxiZ3_pBfGU", language: 'pt-BR', region: 'BR' }}
        defaultCenter={props.referential.center}
        defaultZoom={props.referential.zoom}
      >

        {props.associatesData.map((associate) => (
          <LocationPin
            lat={associate.latLong.lat}
            lng={associate.latLong.lng}
            text={
              <>
                {associate.name} {associate.professional_number && `- ${associate.professional_number}`}<br />
                {formatPhoneNumber(associate.phone)} <br />
                {associate.duration} / {associate.distance}
              </>
            }
          />
        ))}
      </GoogleMapReact>
    </div >
  )
}
