import React, { useState, useEffect } from "react";

import api from "../../../services/api";

import { Content, CardsContainer } from "./styles";
import { i18n } from "../../../localization/i18n";

import { useNavigate, useParams } from "react-router-dom";
import { NewProductBox } from "../../../components/NewProductBox";
import { SolutionModal } from "../../../components/SolutionModal";
import { useAuth } from "../../../hooks/AuthContext";

import { showToast } from "../../../components/CustomToast";
import { useOnboard } from "../../../hooks/OnboardContext";
import { countries, orderTypes, roles } from "../../../utils/constants";
import { orderTypeOptions } from "../mocks";
import { usePrinters } from "../../../hooks/PrintersContext";
import { IPrice } from "../dtos";
import { StandardOrderConfirmationModal } from "../components/StandardOrderConfirmationModal";
export interface IPrinter {
  id: string;
  name: string;
}

export interface ISolution {
  id: string;
  name: string;
  sides: string[];
  name_key: string;
  category_key: string;
  field_key: string;
  form_type?: string;
  link_img: string;
  download_link: string;
  category: string;
  link_guide: string;
  number_measures: number;
  sample: ISample;
  solution_category: ICategory;
  is_custom: boolean;
  is_standard: boolean;
  is_download: boolean;
  standards: Array<any>;
}

export interface SelectedSolution {
  id: string;
  name: string;
  solution_category: any;
  application_video_link?: string;
  background_image_link?: string;
  link_guide?: string;
  description?: string;
}

export interface ISample {
  id?: string;
  solution_id?: string;
  printer_id?: string;
  link?: string;
}

export interface ICategory {
  id: string;
  name: string;
  category_key: string;
  field_key: string;
}

export interface IStandardFileChoice {
  standard: {
    id: string,
    size: string,
  }
  side: string;
  printer_id: string;
}

export default function Solutions() {
  const { setNavigationAction, user, currency } = useAuth();
  const { licensee } = useOnboard();
  const navigate = useNavigate();
  const { userHasPrinter, defaultPrinter } = usePrinters();
  const [filePrice, setFilePrice] = useState<IPrice>();

  const [solutions, setSolutions] = useState<ISolution[]>([]);
  const [selectedSolution, setSelectedSolution] = useState<SelectedSolution>();
  const [solutionModal, setSolutionModal] = useState(false);
  const [ordersCount, setOrdersCount] = useState(false);
  const [selectedSolutionID, setSelectedSolutionID] = useState("")
  const [deliveryAddress, setDeliveryAddress] = useState("")

  const [planSolutions, setPlanSolutions] = useState<ISolution[]>([]); // Aqui é onde eu vou setar o array de ID que vier do back
  const [standardFileChoiceData, setStandardFileChoiceData] = useState<IStandardFileChoice>({
    printer_id: defaultPrinter && defaultPrinter?.value
  } as IStandardFileChoice)

  let { category_key } = useParams<keyof ICategory>();

  async function getAvailableSolutionsFromSignature() {
    try {
      await api
        .get(`/signatures/solutions?category_key=${category_key}`)
        .then((response) => {
          setPlanSolutions(response.data);
        });
    } catch (error) {
      showToast({
        type: "error",
        message: i18n.t("financial.error.get_signature"),
      });
    }
  }

  async function getSolutions() {
    try {
      api.get(`/categories/key/${category_key}`).then((response) => {
        setSolutions(response.data.solutions);
      });
    } catch (error) {
      showToast({
        type: "error",
        message: "Não foi possivel carregar as soluções",
      });
    }
  }

  async function handleSubmit() {
    const abortController = new AbortController();
    let signal = abortController.signal;


    await api
      .get(`/prices/calc/${selectedSolutionID}`)
      .then(async ({ data }) => {
        if (!licensee) {
          return;
        }

        const body = {
          provider: !userHasPrinter ? licensee.provider_id : null,
          solution_id: selectedSolutionID,
          category:
            !userHasPrinter
              ? orderTypes.fixiter
              : orderTypeOptions.find((option) => option.value === 'is_patient')
                ?.value || orderTypes.patient,
          printer_id:
            user.role === roles.licensee || user.role === roles.employee
              ? defaultPrinter?.value
              : undefined,
          total: data.file_price,
          solution_type: "standard",
          licensee_id: licensee.id,
          quantity: 1,
          delivery_address: deliveryAddress
        };

        try {
          /*
            Faltando Color, solution id, printer_id,
          */
          if (
            !standardFileChoiceData.standard ||
            !standardFileChoiceData.side ||
            (userHasPrinter &&
              !standardFileChoiceData.printer_id)
          ) {
            showToast({
              type: "error",
              message: "Dados incompletos",
            });
            return;
          }

          await api.post("/orders", body, { signal }).then(async (response) => {
            try {
              console.log(response.data)
              const { data } = await api.post("/file-choice", {
                order_id: response.data.id,
                printer_id: standardFileChoiceData.printer_id,
                side: standardFileChoiceData.side,
                solution_standard_id: standardFileChoiceData.standard.id,
              }, { signal });
              return data;
            } catch (e: any) {
              console.log(e)
              e?.response?.data?.message &&
                showToast({
                  type: "error",
                  message: i18n.t(e.response.data.message),
                });
            }
          })

          showToast({
            type: "success",
            message: i18n.t("customSolution.successToast"),
          });

          user.role === roles.fixiter || user.role === roles.employee
            ? navigate(`/${user.role.toLowerCase()}/orders`)
            : navigate("/orders");
        } catch (error) {
          showToast({
            type: "error",
            message: "Não foi possível salvar pedido",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setFilePrice(undefined);
      });

    return () => abortController.abort();
  }

  async function getUserOrdersCount() {
    const date = new Date();
    const queryList = [];
    queryList.push(`month=${date.getMonth() + 1}`);
    queryList.push(`year=${date.getFullYear()}`);

    try {
      await api
        .get(`/orders/user/verify?${queryList.join("&")}`)
        .then((response) => {
          setOrdersCount(response.data);
        });
    } catch (error) {
      return;
    } finally {
      if (ordersCount) {
        showToast({
          type: "error",
          message: "Você atingiu seu limite de pedidos por mês, contate o SAF!",
        });
      }
    }
  }

  function changeOrderData(data: any) {
    setDeliveryAddress(data.delivery_address)
  }


  useEffect(() => {
    if (category_key && solutions?.length) {
      getAvailableSolutionsFromSignature();
    }
  }, [category_key, solutions]);

  useEffect(() => {
    if (category_key && licensee) {
      getSolutions();
    }
  }, [category_key, licensee]);

  useEffect(() => {
    setNavigationAction({
      action: "back",
      hasButton: true,
      description: i18n.t("solutions.solutions.header"),
    });
  }, [setNavigationAction]);

  return (
    <>
      <Content>
        {selectedSolution && (
          <SolutionModal
            open={solutionModal}
            closeModal={() => setSolutionModal(false)}
            solution={selectedSolution}
            licenseeCountry={
              licensee?.country &&
                countries[licensee.country as keyof typeof countries]
                ? countries[licensee.country as keyof typeof countries]
                : countries.default
            }
          />
        )}

        <CardsContainer>
          {solutions?.map((solution) => {
            const availableSolution = planSolutions?.find(
              (planSolution) => planSolution.id === solution.id
            );
            return (
              <>
                {solution.id === selectedSolutionID &&
                  <StandardOrderConfirmationModal
                    open={solution.id === selectedSolutionID}
                    solution={solution}
                    standardFileChoice={standardFileChoiceData}
                    setStandardFileChoice={setStandardFileChoiceData}
                    handleCloseModal={() => {
                      setSelectedSolutionID("")
                      setStandardFileChoiceData({ printer_id: defaultPrinter?.value } as IStandardFileChoice)
                    }}
                    changeOrder={changeOrderData}
                    onConfirmOrder={handleSubmit}
                    currency={currency}
                    loading={false} />
                }

                <NewProductBox
                  licenseeCountry={
                    licensee?.country &&
                      countries[licensee.country as keyof typeof countries]
                      ? countries[licensee.country as keyof typeof countries]
                      : countries.default
                  }
                  solutionId={solution.id}
                  link_img={solution.link_img}
                  solutionName={solution.name}
                  solutionCategory={solution.category}
                  solutionKey={solution.name_key}
                  solutionForm={solution.form_type}
                  is_custom={solution.is_custom}
                  is_standard={solution.is_standard}
                  is_download={solution.is_download}
                  download_link={solution.download_link}
                  standards={solution.standards}
                  isAvailable={!planSolutions || !!availableSolution}
                  ordersCount={true}
                  handleCreateStandardOrder={() => {
                    setSelectedSolutionID(solution.id)
                  }}
                  onInfoClick={() => {
                    setSelectedSolution({
                      id: solution.id,
                      name: solution.name,
                      solution_category: solution.solution_category,
                      link_guide: solution.link_guide,
                    });
                    setSolutionModal(true);
                  }}
                />
              </>
            );
          })}
        </CardsContainer>
      </Content>
    </>
  );
}
