import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const BackgroundContainer = styled.div`
  height: 100vh;
  background-color: white;
`
export const FormContainer = styled.form`
    min-width: 970px;
    max-width: 1150px;
    padding: 30px 30px;
    margin: 30px 0 30px;
    border-radius: 10px;
    background: var(--white);
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.25);

    @media (max-width: 1000px) {
        min-width: 100%;
        width: 100%;
        padding: 20px;
    }
`

export const Header = styled.h1`
    color: var(--dark-gray);
    margin-top: 20px;
`;

export const InputContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @media (max-width: 1000px) {
        grid-template-columns: 1fr;
    }
`;

export const SliderContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
    padding: 20px;

    @media (max-width: 1000px) {
        padding: 20px;
    }
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`

export const AssociatesAndMapContainer = styled.div`
    display: flex;
    gap: 2rem;
    flex-direction: row;
    width: 100%;
    padding: 2rem 0;
    height: 75vh;

    @media (max-width: 1000px) {
      flex-direction: column;
      height: 100vh;
    }
`


export const AssociatesContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
    overflow-y: auto;

    @media (max-width: 1000px) {
      height: 100%;
    }
`
export const AssociatesNotFoundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 2rem 0;
  font-size: 1.3rem;
  color: var(--gray);
`

export const MapContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;

    @media (max-width: 1000px) {
        height: 100%;
    }
`
